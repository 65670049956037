import { loadingConstants } from "../constants/loadingConstants";
import { LoadingState } from "../types/loadingTypes";

const initialState: LoadingState = {
  isLoading: false,
  isLoadingActive: true,
  isLoadingCancelled: true,
  isLoadingFailed: true,
  isLoadingPaused: true,
  isLoadingExpired: true,
  isLoadingOTP: true,
  isLoadingSubOrders: true,
  isLoadingSubscriptionAdditionalProducts: false,
};

export default function loading(state: LoadingState = initialState, action: any) {
  switch (action.type) {
    case loadingConstants.SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case loadingConstants.SET_LOADING_ACTIVE:
      return {
        ...state,
        isLoadingActive: true,
      };
    case loadingConstants.SET_LOADING_CANCELLED:
      return {
        ...state,
        isLoadingCancelled: true,
      };
    case loadingConstants.SET_LOADING_FAILED:
      return {
        ...state,
        isLoadingFailed: true,
      };
    case loadingConstants.SET_LOADING_PAUSED:
      return {
        ...state,
        isLoadingPaused: true,
      };
    case loadingConstants.SET_LOADING_EXPIRED:
      return {
        ...state,
        isLoadingExpired: true,
      };
    case loadingConstants.SET_LOADING_OTP:
      return {
        ...state,
        isLoadingOTP: true,
      };
    case loadingConstants.SET_LOADING_SUB_ORDERS:
      return {
        ...state,
        isLoadingSubOrders: true,
      };
    case loadingConstants.SET_LOADING_SUB_ADDITIONAL_PROUCTS:
      return {
        ...state,
        isLoadingSubscriptionAdditionalProducts: true,
      };
    case loadingConstants.CLEAR_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case loadingConstants.CLEAR_LOADING_ACTIVE:
      return {
        ...state,
        isLoadingActive: false,
      };
    case loadingConstants.CLEAR_LOADING_CANCELLED:
      return {
        ...state,
        isLoadingCancelled: false,
      };
    case loadingConstants.CLEAR_LOADING_FAILED:
      return {
        ...state,
        isLoadingFailed: false,
      };
    case loadingConstants.CLEAR_LOADING_PAUSED:
      return {
        ...state,
        isLoadingPaused: false,
      };
    case loadingConstants.CLEAR_LOADING_EXPIRED:
      return {
        ...state,
        isLoadingExpired: false,
      };
    case loadingConstants.CLEAR_LOADING_OTP:
      return {
        ...state,
        isLoadingOTP: false,
      };
    case loadingConstants.CLEAR_LOADING_SUB_ORDERS:
      return {
        ...state,
        isLoadingSubOrders: false,
      };
    case loadingConstants.CLEAR_LOADING_SUB_ADDITIONAL_PROUCTS:
      return {
        ...state,
        isLoadingSubscriptionAdditionalProducts: false,
      };
    default:
      return state;
  }
}
