import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { SubscriptionsState, SubscriptionStatusKey } from "../types/subscriptionTypes";
import SubscriptionsTable from "../components/Tables/SubscriptionsTable";
import { isPetlabUser } from "../utilities/petlabUser";

const Subscriptions: React.FC = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState("");

  const subscriptionsState: SubscriptionsState = useMappedState((state): any => state.subscriptions);
  const subscriptions = subscriptionsState.subscriptionsList;
  const { pageCount } = subscriptionsState;
  const { search } = subscriptionsState;
  const { filters } = subscriptionsState;
  const { page } = subscriptionsState;

  const statuses: number[] | undefined = [];
  Object.keys(filters.status).forEach((filterStatus) => {
    if (filters.status[filterStatus]) {
      statuses.push(Number(SubscriptionStatusKey.get(filterStatus)));
    }
  });

  let nextBillingDate: any;
  if (filters.nextBillingDate) {
    nextBillingDate = filters.nextBillingDate;
  }

  let externalVendor: any;

  const status = statuses.length > 0 ? statuses.join(",") : null;

  useEffect(() => {
    if ((page && isPetlabUser(search)) || search || sortBy || status || nextBillingDate || externalVendor) {
      dispatch({
        type: "GET_ALL_SUBSCRIPTIONS",
        payload: {
          page: page || 1,
          search,
          sorting: sortBy,
          status,
          nextBillingDate,
          externalVendor,
        },
      });
    }
  }, [dispatch, page, search, sortBy, status, nextBillingDate, externalVendor]);

  const sortByForTable = useCallback((sortByValue) => {
    const sorting: any = {};
    sortByValue.forEach((value: any) => {
      const key = !value.desc ? "asc" : "desc";
      sorting[key] = [value.id];
    });
    setSortBy(sorting);
  }, []);

  const setPage = useCallback(
    async (pageNumber: number) => {
      dispatch({
        type: "SET_PAGE_SUBSCRIPTION",
        payload: {
          page: pageNumber,
        },
      });
    },
    [dispatch]
  );

  return (
    <SubscriptionsTable
      subscriptions={subscriptions}
      page={page}
      pageCount={pageCount}
      search={search}
      setPage={setPage}
      sortByForTable={sortByForTable}
    />
  );
};

export default Subscriptions;
