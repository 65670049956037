import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import CustomerForm from "../components/Forms/CustomerForm";
import NavConfirmationButton from "../components/Buttons/NavConfirmationButton";
import { Routes } from "../constants/routes";
import { ReactComponent as BackIcon } from "../icons/leftArrow.svg";
import CustomerStatus from "../components/CustomerStatus";
import CancelButton from "../components/Buttons/CancelButton";
import SaveButton from "../components/Buttons/SaveButton";
import EditButton from "../components/Buttons/EditButton";
import SubscriptionsList from "../components/SubscriptionsList";
import Activity from "../components/Activity";
import AddressesForm from "../components/AddressesForm";
import { Subscription, SubscriptionStatus } from "../types/subscriptionTypes";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { customerConstants } from "../constants/customerConstants";
import { getSimpleDateFormat } from "../utilities/date";
import OtherTypesSubscriptionsList from "../components/OtherTypesSubscriptionsList";
import OneTimePurchases from "../components/OneTimePurchases";
import { compareObjects } from "../utilities/compareObjects";
import { validateFields } from "../utilities/validateFields";

const CanceledSubscriptionsList = React.lazy(() => import("../components/CanceledSubscriptionsList"));

const keys = ["street", "street_2", "city", "stateProvince", "zip", "country", "firstName", "lastName"];

const Customer = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const nextDate = date.getTime() / 1000;
  const dispatch = useDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState<SubscriptionStatus>(SubscriptionStatus.ACTIVE);
  const [cancelReason, setCancelReason] = useState(0);
  const [cancelMessage, setCancelMessage] = useState("");
  const [newDate, setNewDate] = useState(nextDate);
  const [subscriptionId, setSubscriptionId] = useState("");
  const onEdit = () => setIsEditing(true);
  const customer = useMappedState((state): any => state.customers.customer);
  const [subscriptions, setSubscriptions] = useState(customer.subscriptions);
  const [cancelledSubscriptions, setCancelledSubscriptions] = useState(customer.cancelledSubscriptions);
  const [failedSubscriptions, setFailedSubscriptions] = useState(customer.failedSubscriptions);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState(customer.expiredSubscriptions);
  const [pausedSubscriptions, setPausedSubscriptions] = useState(customer.pausedSubscriptions);
  const isLoadingActive = useMappedState((state) => state.loading.isLoadingActive);
  const isLoadingCancelled = useMappedState((state) => state.loading.isLoadingCancelled);
  const isLoadingFailed = useMappedState((state) => state.loading.isLoadingFailed);
  const isLoadingPaused = useMappedState((state) => state.loading.isLoadingPaused);
  const isLoadingExpired = useMappedState((state) => state.loading.isLoadingExpired);
  const [purchases, setPurchases] = useState(customer.purchases);
  const [initialCustomer, setInitialCustomer] = useState(null);
  const isLoadingOTP = useMappedState((state): any => state.loading.isLoadingOTP);
  const isLoading = useMappedState((state): any => state.loading.isLoading);
  const [errors, setErrors] = useState({});

  const subscriptionStatuses = {
    [SubscriptionStatus.ACTIVE]: customerConstants.GET_ACTIVE_SUBSCRIPTIONS,
    [SubscriptionStatus.CANCELED]: customerConstants.GET_CANCELLED_SUBSCRIPTIONS,
    [SubscriptionStatus.EXPIRED]: customerConstants.GET_EXPIRED_SUBSCRIPTIONS,
    [SubscriptionStatus.PAUSED]: customerConstants.GET_PAUSED_SUBSCRIPTIONS,
    default: customerConstants.GET_FAILED_SUBSCRIPTIONS,
  };

  const dispatchSubscriptions = useCallback((type) => {
    dispatch({
      type,
      payload: { id: customerId },
    });
  }, [customerId, dispatch]);

  useEffect(() => {
    Object.values(subscriptionStatuses).forEach((status) => {
      dispatchSubscriptions(status);
    });
  }, [dispatchSubscriptions]);

  useEffect(() => {
    dispatch({
      type: customerConstants.GET_CUSTOMER,
      payload: customerId,
    });
  }, [customerId, dispatch]);

  const onCancel = () => {
    setIsEditing(false);
    dispatch({
      type: customerConstants.GET_CUSTOMER,
      payload: customerId,
    });
  };

  useEffect(() => {
    setSubscriptions(customer.subscriptions);
  }, [customer.subscriptions]);

  useEffect(() => {
    setCancelledSubscriptions(customer.cancelledSubscriptions);
  }, [customer.cancelledSubscriptions]);

  useEffect(() => {
    setFailedSubscriptions(customer.failedSubscriptions);
  }, [customer.failedSubscriptions]);

  useEffect(() => {
    setExpiredSubscriptions(customer.expiredSubscriptions);
  }, [customer.expiredSubscriptions]);

  useEffect(() => {
    setPausedSubscriptions(customer.pausedSubscriptions);
  }, [customer.pausedSubscriptions]);

  useEffect(() => {
    setPurchases(customer.purchases);
  }, [customer.purchases]);

  useEffect(() => {
    setInitialCustomer(customer);
  }, [customer.id]);

  useEffect(() => {
    dispatch({
      type: customerConstants.GET_ONE_TIME_PURCHASES,
      payload: {
        id: customerId,
        page: customer.page || 1,
      },
    });
  }, [customer.page]);

  const handleStatus = (val: number) => {
    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          status: val,
        },
      },
    });
  };

  const handleChange = (e: any) => {
    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const cancelSubscription = (id: string | number) => {
    dispatch({
      type: subscriptionConstants.CANCEL_SUBSCRIPTION,
      payload: {
        id,
        data: {
          cancellationReason: cancelReason,
          otherElaboration: cancelMessage,
        },
      },
    });

    const filteredSubscriptions = customer.subscriptions.filter((sub: Subscription) => +id !== +sub.id);
    const filteredCancelSubscriptions = customer.subscriptions.filter((sub: Subscription) => (
      +id === +sub.id));

    cancelledSubscriptions.push(filteredCancelSubscriptions[0]);
    cancelledSubscriptions.sort((a, b) => b.id - a.id);

    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          subscriptions: filteredSubscriptions,
          cancelledSubscriptions,
        },
      },
    });
    setCancelledSubscriptions(cancelledSubscriptions);

    setSubscriptions(filteredSubscriptions);
  };

  const restartSubscription = () => {
    const subscription = customer.cancelledSubscriptions.find(
      (sub: Subscription) => +subscriptionId === +sub.id
    );

    if (!subscription) {
      console.error("Subscription not found");
      return;
    }

    dispatch({
      type: subscriptionConstants.RESTART_SUBSCRIPTION,
      payload: {
        id: subscriptionId,
        data: {
          new_rebill_date: getSimpleDateFormat(newDate),
          quantity: subscription.line_items[0].quantity,
          frequency: `${subscription.schedule.schedule_value}:${subscription.schedule.schedule_type}`,
        },
      },
    });

    const filteredCancelSubscriptions = customer.cancelledSubscriptions.filter(
      (sub: Subscription) => +subscriptionId !== +sub.id
    );
    setCancelledSubscriptions(filteredCancelSubscriptions);

    const updatedSubscription = { ...subscription, schedule: { ...subscription.schedule, next_billing_date: newDate } };
    const newSubscriptions = [...subscriptions.filter((sub: Subscription) => +sub.id !== +subscriptionId),
      updatedSubscription].sort((a, b) => +b.id - +a.id);

    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          subscriptions: newSubscriptions,
          cancelledSubscriptions: filteredCancelSubscriptions,
        },
      },
    });
    setSubscriptions(newSubscriptions);
  };

  const onSave = useCallback(async () => {
    setIsSaving(true);
    const changedValues = compareObjects(initialCustomer, customer);
    if (changedValues) {
      const inputErrors = validateFields(changedValues);
      if (Object.keys(inputErrors).length > 0) {
        setErrors(inputErrors);
        setIsSaving(false);
        return;
      }

      dispatch({
        type: customerConstants.SEND_UPDATE_DATA,
        payload: { id: customerId, customer: changedValues },
      });
      setInitialCustomer(customer);
      setErrors({});
    }

    setErrors({});
    setIsEditing(false);
    setIsSaving(false);
  }, [setIsSaving, setIsEditing, customerId, customer, dispatch, initialCustomer]);

  const renderBody = () => (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          <NavConfirmationButton route={Routes.CUSTOMERS} isEditing={isEditing} isSaving={isSaving} onSave={onSave}>
            <BackIcon />
            Return to Customer List
          </NavConfirmationButton>
          <CustomerStatus
            name={customer.name}
            status={customer.status}
            handleStatus={handleStatus}
            isEditing={isEditing}
            isSaving={isSaving}
          />
        </div>
        {isEditing ? (
          <div>
            <CancelButton style={{ width: 120, height: 48 }} isSaving={isSaving} onCancel={onCancel} />
            <SaveButton showIcon style={{ width: 120, height: 48 }} isSaving={isSaving} onSave={onSave} />
          </div>
        ) : (
          <EditButton showIcon style={{ width: 120, height: 48 }} onEdit={onEdit} />
        )}
      </div>
      <Container fluid className="flex-grow-1 overflow-hidden">
        <Row className="h-100">
          <Col className="p-5 h-100 overflow-auto bg-white">
            <CustomerForm customer={customer} handleChange={handleChange} isEditing={isEditing} errors={errors} />
            <h4 className="mt-2 mb-4">
              {currentTab === SubscriptionStatus.ACTIVE ? (
                isLoadingActive ? (
                  <div className="spinner-block">
                    <Spinner className="spinner" as="span" animation="border" size="sm" role="status" />
                    Active Subscriptions
                  </div>
                )
                  : `${subscriptions.length} Active Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.ACTIVE)}
                >
                  Active Subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.CANCELED ? (
                isLoadingCancelled
                  ? (
                    <div className="spinner-block">
                      <Spinner className="spinner" as="span" animation="border" size="sm" role="status" />
                      Canceled Subscriptions
                    </div>
                  )
                  : `${cancelledSubscriptions.length} Canceled Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.CANCELED)}
                >
                  Canceled subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.FAILED ? (
                isLoadingFailed
                  ? (
                    <div className="spinner-block">
                      <Spinner className="spinner" as="span" animation="border" size="sm" role="status" />
                      Failed Subscriptions
                    </div>
                  )
                  : `${failedSubscriptions.length} Failed Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.FAILED)}
                >
                  Failed subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.PAUSED ? (
                isLoadingPaused
                  ? (
                    <div className="spinner-block">
                      <Spinner className="spinner" as="span" animation="border" size="sm" role="status" />
                      Paused Subscriptions
                    </div>
                  )
                  : `${pausedSubscriptions.length} Paused Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.PAUSED)}
                >
                  Paused subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.EXPIRED ? (
                isLoadingExpired
                  ? (
                    <div className="spinner-block">
                      <Spinner className="spinner" as="span" animation="border" size="sm" role="status" />
                      Expired Subscriptions
                    </div>
                  )
                  : `${expiredSubscriptions.length} Expired Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.EXPIRED)}
                >
                  Expired subscriptions
                </Button>
              )}
            </h4>
            {currentTab === SubscriptionStatus.ACTIVE && !isLoadingActive &&
              <Suspense
                fallback={
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
              >
                <SubscriptionsList
                  cancelSubscription={cancelSubscription}
                  setCancelReason={setCancelReason}
                  setCancelMessage={setCancelMessage}
                  subscriptionIds={subscriptions}
                  cancelReason={cancelReason}
                />
              </Suspense>}
            {currentTab === SubscriptionStatus.CANCELED && !isLoadingCancelled && (
              <Suspense
                fallback={
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
              >
                <CanceledSubscriptionsList
                  subscriptionIds={cancelledSubscriptions}
                  handleRestart={restartSubscription}
                  setNewDate={setNewDate}
                  setSubscriptionId={setSubscriptionId}
                />
              </Suspense>
            )}
            {currentTab === SubscriptionStatus.FAILED && !isLoadingFailed && (
              <Suspense
                fallback={
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
              >
                <OtherTypesSubscriptionsList
                  subscriptions={failedSubscriptions}
                />
              </Suspense>
            )}
            {currentTab === SubscriptionStatus.EXPIRED && !isLoadingExpired && (
              <Suspense
                fallback={
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
              >
                <OtherTypesSubscriptionsList
                  subscriptions={expiredSubscriptions}
                />
              </Suspense>
            )}
            {currentTab === SubscriptionStatus.PAUSED && !isLoadingPaused && (
              <Suspense
                fallback={
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
              >
                <OtherTypesSubscriptionsList
                  subscriptions={pausedSubscriptions}
                />
              </Suspense>
            )}

            <h4>Shopify Orders</h4>
            {
              isLoadingOTP
                ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )
                : purchases.length
                  ? <OneTimePurchases
                      history={purchases}
                  />
                  : <div> No orders found</div>
            }
          </Col>
          {!isEditing ? (
            <Col className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0" style={{ minWidth: 300 }}>
              <Form.Group controlId="shippingAddress">
                <Form.Label className="small font-weight-bold">Shipping Address</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  disabled
                  value={
                    keys.map((key: string) => customer.shippingAddress && customer.shippingAddress[key]).join(" ") || ""
                  }
                  className="bg-gray-light border-0 p-0 "
                />
              </Form.Group>
              <Form.Group controlId="billingAddress">
                <Form.Label className="small font-weight-bold">Billing Address</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  disabled
                  value={
                    keys.map((key: string) => customer.billingAddress && customer.billingAddress[key]).join(" ")
                  }
                  className="bg-gray-light border-0 p-0 "
                />
              </Form.Group>
              <hr />
              <Activity customer={customer} isSaving={isSaving} />
            </Col>
          ) : (
            <Col
              className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0"
              style={{ minWidth: 300, overflow: "auto" }}
            >
              <AddressesForm
                addresses={customer}
                action={customerConstants.UPDATE_CUSTOMER}
                isEditing={isEditing}
                isSaving={isSaving}
                errors={errors}
              />
            </Col>
          )}
        </Row>

      </Container>
      {
        isLoading && (
          <div className="centered-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )
      }
    </>
  );

  return (
    <>
      {!customer.id || String(customer.id) !== customerId ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Customer;
