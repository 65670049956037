import { loadingConstants } from "../constants/loadingConstants";

export const setLoading = () => ({
  type: loadingConstants.SET_LOADING,
});
export const setLoadingActive = () => ({
  type: loadingConstants.SET_LOADING_ACTIVE,
});
export const setLoadingCancelled = () => ({
  type: loadingConstants.SET_LOADING_CANCELLED,
});
export const setLoadingFailed = () => ({
  type: loadingConstants.SET_LOADING_FAILED,
});
export const setLoadingPaused = () => ({
  type: loadingConstants.SET_LOADING_PAUSED,
});
export const setLoadingExpired = () => ({
  type: loadingConstants.SET_LOADING_EXPIRED,
});
export const setLoadingOTP = () => ({
  type: loadingConstants.SET_LOADING_OTP,
});
export const setLoadingSubOrders = () => ({
  type: loadingConstants.SET_LOADING_SUB_ORDERS,
});
export const setLoadingSubscriptionAdditionalProducts = () => ({
  type: loadingConstants.SET_LOADING_SUB_ADDITIONAL_PROUCTS,
});
export const clearLoading = () => ({
  type: loadingConstants.CLEAR_LOADING,
});
export const clearLoadingActive = () => ({
  type: loadingConstants.CLEAR_LOADING_ACTIVE,
});
export const clearLoadingCancelled = () => ({
  type: loadingConstants.CLEAR_LOADING_CANCELLED,
});
export const clearLoadingFailed = () => ({
  type: loadingConstants.CLEAR_LOADING_FAILED,
});
export const clearLoadingPaused = () => ({
  type: loadingConstants.CLEAR_LOADING_PAUSED,
});
export const clearLoadingExpired = () => ({
  type: loadingConstants.CLEAR_LOADING_EXPIRED,
});
export const clearLoadingOTP = () => ({
  type: loadingConstants.CLEAR_LOADING_OTP,
});
export const clearLoadingSubOrders = () => ({
  type: loadingConstants.CLEAR_LOADING_SUB_ORDERS,
});
export const clearLoadingSubscriptionAdditionalProducts = () => ({
  type: loadingConstants.CLEAR_LOADING_SUB_ADDITIONAL_PROUCTS,
});
