import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";

import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import EnumDropdown from "../EnumDropdown";
import {
  BillingFrequency,
  BillingFrequencyDisplayName,
  SubscriptionStatus,
  SubscriptionStatusDisplayName,
} from "../../types/subscriptionTypes";
import {
  getCorrectFormat,
  getDateAtTimeFormat,
  getDateAtTimeFormatString,
  getNextBillingDateFormat,
} from "../../utilities/date";
import OrderDetailsTable from "../OrderDetailsTable";
import { subscriptionConstants } from "../../constants/subscriptionConstants";
import CustomerInfoPane from "../CustomerInfoPane";
import CancelSubscriptionModal from "../Modals/CancelSubscriptionModal";
import DownsellModal from "../Modals/DownsellModal";
import OrderHistory from "../OrderHistory";
import SubscriptionDetailsTable from "../SubscriptionDetailsTable";

export const SubscriptionInfoBlock = ({
  isSaving, isEditing, setIsEditing,
  initialSubscription, setInitialSubscription,
  saveRow, onCancelSubscription, setCancelMessage,
  setShowCancelModal, setShowRestartModal,
  setShowDownsellModal, cancelReason, setCancelReason,
}) => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const dispatch = useDispatch();

  const subscription = useMappedState((state): any => state.subscriptions.subscription);
  const history = useMappedState((state): any => state.subscriptions.history);
  const pendingOrder = useMappedState((state): any => state.subscriptions.pendingOrder);
  const isLoadingSubOrders = useMappedState((state): any => state.loading.isLoadingSubOrders);
  const [showAllHistory, setShowAllHistory] = useState(false);
  const [isCanChange, setIsCanChange] = useState(true);
  const [lastSubscriptionsDay, setLastSubscriptionsDay] = useState<string>();
  const panelRef = useRef<HTMLDivElement>(null);
  const {
    signupDate,
    previousBillingDate,
    billing,
    billingFrequency,
    status,
    shippingAddress,
  } =
    subscription;

  useEffect(() => {
    if (isCanChange) {
      const lastDay = moment(billing?.nextBillingDate)
        .add(6, "M")
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      setLastSubscriptionsDay(lastDay);
    }
  }, [billing?.nextBillingDate]);

  const handleStatusChange = (value: SubscriptionStatus) => {
    if (value === SubscriptionStatus.CANCELED) {
      setShowCancelModal(true);
    } else if (value === SubscriptionStatus.ACTIVE) {
      setShowRestartModal(true);
    } else {
      dispatch({
        type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
        payload: {
          status: value,
        },
      });
    }
  };

  const handleFrequencyChange = (value: BillingFrequency) => {
    const frequency = value.split(":");
    const copyDate = new Date(subscription.previousBillingDate || subscription.signupDate);

    if (frequency[1] === "month") {
      copyDate.setMonth(copyDate.getMonth() + Number(frequency[0]));
    } else {
      copyDate.setDate(copyDate.getDate() + Number(frequency[0]) * 7);
    }

    const currentDate = new Date();

    if (copyDate < currentDate) {
      copyDate.setDate(currentDate.getDate() + 1);
    }
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_FREQUENCY,
      payload: {
        frequency: value,
        nextBillingDate: getNextBillingDateFormat(copyDate),
      },
    });
  };

  const getBillingFrequencyKey = useCallback((frequency) => {
    if (!frequency?.schedule_type) return frequency;
    return `${frequency.schedule_value}:${frequency.schedule_type}`;
  }, []);

  const toggleShowAllHistory = () => {
    setShowAllHistory(!showAllHistory);
    if (panelRef.current) {
      panelRef.current.scrollTop = 0;
    }
  };

  const tomorrow = moment()
    .add(1, "days")
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");

  const sixMonthsFromToday = moment().add(6, "months").startOf("day").format("YYYY-MM-DD HH:mm:ss");

  const handleNextDateChange = (value: Date) => {
    setIsCanChange(false);
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
      payload: {
        status: moment(value).isAfter(lastSubscriptionsDay) ? SubscriptionStatus.PAUSED : SubscriptionStatus.ACTIVE,
      },
    });

    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_DATE,
      payload: {
        nextBillingDate: getCorrectFormat(value.getTime()),
      },
    });
  };

  const generateNewDate = useCallback((param) => new Date(param), []);

  const getSelectedDate = (billingDate) => {
    const formattedDate = getCorrectFormat(billingDate);
    if (!formattedDate) return null;
    return new Date(formattedDate);
  };

  return (
    <Container fluid className="flex-grow-1 overflow-hidden">
      <Row className="h-100">
        <Col className="p-5 h-100 overflow-auto bg-white" ref={panelRef}>
          <Row>
            <Col xl={3}>
              <h6 className="m-0">
                <small className="font-weight-bold">Subscription Sign Up Date</small>
              </h6>
              <p>{signupDate}</p>
            </Col>
            <Col xl={3}>
              <h6 className="m-0">
                <small className="font-weight-bold">Previous Billing Date</small>
              </h6>
              <p>{previousBillingDate || signupDate}</p>
            </Col>
            <Col xl={1}>
              <h6 className="m-0">
                <small className="font-weight-bold">Status</small>
              </h6>
              {isEditing && !isSaving ? (
                <EnumDropdown
                  enumerable={SubscriptionStatus}
                  enumDisplayName={SubscriptionStatusDisplayName}
                  value={status.status}
                  setValue={(value) => handleStatusChange(value)}
                />
              ) : (
                <p>{SubscriptionStatusDisplayName.get(status.status)}</p>
              )}
            </Col>
            <Col>
              <div
                className="d-flex flex-column flex-md-row justify-content-xl-end mb-4"
                style={{ gap: "10px" }}
              >
                {status !== SubscriptionStatus.CANCELED
                  && subscription.downsell_discount_is_available
                  && (
                    <DownsellModal
                      setShowDownsellModal={setShowDownsellModal}
                    />
                  )}
                {status !== SubscriptionStatus.CANCELED && (
                  <CancelSubscriptionModal
                    subscriptionId={subscription}
                    setCancelReason={setCancelReason}
                    setCancelMessage={setCancelMessage}
                    cancelSubscription={onCancelSubscription}
                    cancelReason={cancelReason}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="m-0">
                <small className="font-weight-bold">Billing Frequency</small>
              </h6>
              {isEditing && !isSaving ? (
                <EnumDropdown
                  enumerable={BillingFrequency}
                  enumDisplayName={BillingFrequencyDisplayName}
                  value={getBillingFrequencyKey(billingFrequency)}
                  setValue={(value) => handleFrequencyChange(value)}
                />
              ) : (
                <p>{BillingFrequencyDisplayName.get(getBillingFrequencyKey(billingFrequency))}</p>
              )}
            </Col>
            <Col>
              <h6 className="m-0">
                <small className="font-weight-bold">Next Billing Date</small>
              </h6>
              {isEditing && !isSaving ? (
                <DatePicker
                  showTimeSelect
                  minDate={generateNewDate(tomorrow)}
                  maxDate={generateNewDate(sixMonthsFromToday)}
                  selected={getSelectedDate(billing?.nextBillingDate)}
                  onChange={(choiceDate: Date) => handleNextDateChange(choiceDate)}
                  dateFormat="MM/dd/yyyy h:mm aa"
                />
              ) : (
                <p>{getDateAtTimeFormat(billing?.nextBillingDate)}</p>
              )}
            </Col>
            <Col className="d-none d-xl-block">
              <h6 className="m-0">
                <small className="font-weight-bold" />
              </h6>
              <p />
            </Col>
            <Col className="d-none d-xl-block">
              <h6 className="m-0">
                <small className="font-weight-bold" />
              </h6>
              <p />
            </Col>
          </Row>
          <hr />
          {!showAllHistory && (
            <>
              <h4>Subscription details</h4>
              <SubscriptionDetailsTable
                initialSubscription={initialSubscription}
                isEditing={isEditing}
                isSaving={isSaving}
                setIsEditing={setIsEditing}
                setInitialSubscription={setInitialSubscription}
                saveRow={saveRow}
                showFooter
                className="mb-4"
                country={shippingAddress.country}
              />
              {pendingOrder.length ? (
                <>
                  <hr />
                  <OrderDetailsTable
                    order={pendingOrder}
                    currency={subscription.currency}
                    shop={history[0]?.shop_name}
                    isDownsell={subscription.downsell_discount_is_available}
                    header={
                      <>
                        <h4 className="m-0">Upcoming order #{pendingOrder[0].order_id}</h4>
                        <p className="small m-0 ml-3">
                          {getDateAtTimeFormat(billing?.nextBillingDate) || getDateAtTimeFormatString(signupDate)}
                        </p>
                      </>
                    }
                  />
                </>
              ) : (
                <div>No upcoming orders</div>
              )}
              <hr />
            </>
          )}
          {isLoadingSubOrders ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : history.length > 0 ? (
            <OrderHistory
              history={history}
              currency={subscription.currency}
              showAllHistory={showAllHistory}
              toggleShowAllHistory={toggleShowAllHistory}
            />
          ) : (
            <div>No Order History</div>
          )}
        </Col>
        <Col
          className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0"
          style={{ minWidth: 275 }}
        >
          <CustomerInfoPane
            customer={subscription.customer}
            address={subscription}
            isEditing={isEditing}
            isSaving={isSaving}
          />
        </Col>
      </Row>
    </Container>
  );
};
