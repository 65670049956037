export const subscriptionConstants = {
  GET_ALL_SUBSCRIPTIONS: "GET_ALL_SUBSCRIPTIONS",
  SET_ALL_SUBSCRIPTIONS: "SET_ALL_SUBSCRIPTIONS",
  GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  GET_SUBSCRIPTION_ORDERS: "GET_SUBSCRIPTION_ORDERS",
  SET_SUBSCRIPTION_ORDERS: "SET_SUBSCRIPTION_ORDERS",
  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION_STATUS: "UPDATE_SUBSCRIPTION_STATUS",
  UPDATE_SUBSCRIPTION_BILLING_FREQUENCY: "UPDATE_SUBSCRIPTION_BILLING_FREQUENCY",
  UPDATE_SUBSCRIPTION_BILLING_DATE: "UPDATE_SUBSCRIPTION_BILLING_DATE",
  UPDATE_SUBSCRIPTION_DETAILS: "UPDATE_SUBSCRIPTION_DETAILS",
  UPDATE_SUBSCRIPTION_ADDRESS: "UPDATE_SUBSCRIPTION_ADDRESS",
  SEARCH_SUBSCRIPTIONS: "SEARCH_SUBSCRIPTIONS",
  SET_SORT_SUBSCRIPTIONS: "SET_SORT_SUBSCRIPTIONS",
  SET_FILTER_SUBSCRIPTION: "SET_FILTER_SUBSCRIPTION",
  SET_PAGE_SUBSCRIPTION: "SET_PAGE_SUBSCRIPTION",
  DOWNSELL_SUBSCRIPTION: "DOWNSELL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  RESTART_SUBSCRIPTION: "RESTART_SUBSCRIPTION",
  ADD_SUBSCRIPTION_ADDITIONAL_PRODUCTS: "ADD_SUBSCRIPTION_ADDITIONAL_PRODUCTS",
  UPDATE_SUBSCRIPTION_ADDITIONAL_PRODUCTS: "UPDATE_SUBSCRIPTION_ADDITIONAL_PRODUCTS",
  UPDATE_ADDITIONAL_PRODUCTS: "UPDATE_ADDITIONAL_PRODUCTS",
  DELETE_SUBSCRIPTION_ADDITIONAL_PRODUCTS: "DELETE_SUBSCRIPTION_ADDITIONAL_PRODUCTS",
};
