export const loadingConstants = {
  SET_LOADING: "SET_LOADING",
  SET_LOADING_ACTIVE: "SET_LOADING_ACTIVE",
  SET_LOADING_CANCELLED: "SET_LOADING_CANCELLED",
  SET_LOADING_FAILED: "SET_LOADING_FAILED",
  SET_LOADING_PAUSED: "SET_LOADING_PAUSED",
  SET_LOADING_EXPIRED: "SET_LOADING_EXPIRED",
  SET_LOADING_OTP: "SET_LOADING_OTP",
  SET_LOADING_SUB_ORDERS: "SET_LOADING_SUB_ORDERS",
  SET_LOADING_SUB_ADDITIONAL_PROUCTS: "SET_LOADING_SUB_ADDITIONAL_PROUCTS",
  CLEAR_LOADING: "CLEAR_LOADING",
  CLEAR_LOADING_ACTIVE: "CLEAR_LOADING_ACTIVE",
  CLEAR_LOADING_CANCELLED: "CLEAR_LOADING_CANCELLED",
  CLEAR_LOADING_FAILED: "CLEAR_LOADING_FAILED",
  CLEAR_LOADING_PAUSED: "CLEAR_LOADING_PAUSED",
  CLEAR_LOADING_EXPIRED: "CLEAR_LOADING_EXPIRED",
  CLEAR_LOADING_OTP: "CLEAR_LOADING_OTP",
  CLEAR_LOADING_SUB_ORDERS: "CLEAR_LOADING_SUB_ORDERS",
  CLEAR_LOADING_SUB_ADDITIONAL_PROUCTS: "CLEAR_LOADING_SUB_ADDITIONAL_PROUCTS",
};
