import { SubscriptionItem, Subscription } from "../types/subscriptionTypes";
import { Order, Product } from "../types/orderTypes";

export default function transformProductItems(products: Product[] | Order[]) {
  return products.map((item: any) => ({
    ...item,
    productInfo: {
      productId: item.id || 0,
      name: item.package || item.product || item.profile,
      image: item.image || item.image_url,
    },
    subscription_id: item.subscription_id || null,
    price: item.price || 0,
    tax: item.taxAmount || item.tax_amount || 0,
    totalAmount: item.totalAmount || item.total || item.row_total || 0,
  }));
}

export function transformSubscriptionItems(products: SubscriptionItem[] | Subscription[] | Product[]) {
  return products.map((item: any) => ({
    ...item,
    productInfo: {
      productId: item.id || item.product_id || item.primaryProductId || 0,
      name: item.package || item.product || item.profile || item.summary || item.product_name,
      image: item.image || item.image_url,
    },
    quantity: item.quantity || 0,
    price: item.price || 0,
    tax: item.taxAmount || item.tax_amount || 0,
    totalAmount: item.row_total || item.total || 0,
  }));
}
