/* eslint-disable */
import { Address } from "../types/addressType";

const isObject = (item) => typeof item === "object" && item !== null && !Array.isArray(item);
const isEmpty = (obj) => Object.keys(obj).length === 0;

function handleMainProduct(key, object, initialObject) {
  const hasChanges = ["id", "price", "quantity"].some((field) => object[key][field] !== initialObject[key][field]);
  return hasChanges ? {
    id: object[key].id,
    price: object[key].price,
    quantity: object[key].quantity,
  } : null;
}

function getDefaultAddress(address: Address, type: string) {
  const defaultAddress: any = {
    firstName: address.firstName,
    lastName: address.lastName,
    street: address.street,
    street_2: address.street_2,
    zip: address.zip,
    city: address.city,
    stateProvince: address.stateProvince,
    country: address.country,
  };

  if (type === "subscription") {
    defaultAddress.id = address.id || 0;
    defaultAddress.street_2 = address.street_2 || null;
    defaultAddress.street3 = address.street3 || null;
    defaultAddress.phone = address.phone || null;
  }

  return defaultAddress;
}

function handleAddresses(key, object, initialObject, type) {
  const nestedChanges = compareObjects(object[key], initialObject[key], type);
  if (!isEmpty(nestedChanges)) {
    return { ...getDefaultAddress(initialObject[key], type), ...nestedChanges };
  }
  return null;
}

function checkForNestedChanges(key, object, initialObject, type) {
  const nestedChanges = compareObjects(object[key], initialObject[key], type);
  return !isEmpty(nestedChanges) ? nestedChanges : null;
}

function handleSpecialCases(key, object, initialObject, type) {
  switch (key) {
    case "mainProduct":
      return handleMainProduct(key, object, initialObject);
    case "shippingAddress":
    case "billingAddress":
      return handleAddresses(key, object, initialObject, type);
    default:
      return checkForNestedChanges(key, object, initialObject, type);
  }
}

export function compareObjects(object, initialObject, type = "") {
  const changes = {};
  Object.keys(object).forEach((key) => {
    if (key.toLowerCase().includes("subscription")) return;
    if (key in initialObject) {
      if (isObject(object[key])) {
        const nestedChanges = handleSpecialCases(key, object, initialObject, type);
        if (nestedChanges) {
          changes[key] = nestedChanges;
        }
      } else if (object[key] !== initialObject[key]) {
        changes[key] = initialObject[key];
      }
    }
  });

  return isEmpty(changes) ? false : changes;
}
