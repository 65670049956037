import React from "react";
import Button from "react-bootstrap/Button";
import { Customer } from "../types/customerTypes";
import { getDateAtTimeFormatString } from "../utilities/date";

type Props = {
  customer: Customer;
  isSaving: boolean;
};
const Activity: React.FC<Props> = ({ customer, isSaving }) => (
  <div>
    <h6 className="small font-weight-bold">Last Updated</h6>
    <p className="m-0 small">{getDateAtTimeFormatString(customer.updatedAt)}</p>
    {customer.updatedBy && <p className="m-0 mt-n1 b-1 small">by {customer.updatedBy}</p>}
    <Button variant="link" size="sm" className="p-0" disabled={isSaving}>
      View activity
    </Button>
  </div>
);

export default Activity;
