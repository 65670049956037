import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { CellProps } from "react-table";

const EditableCell = <T extends object>({
  value,
  row: { index },
  column: { id },
  updateData,
}: React.PropsWithChildren<CellProps<T>>): React.ReactElement<CellProps<T>> => {
  const [newValue, setNewValue] = React.useState<string>("");
  const [isUpdated, setIsUpdated] = React.useState(false);

  const doChange = (changed: string) => {
    setNewValue(changed);
    setIsUpdated(true);
  };

  const onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!/^[0-9.]*$/.test(e.target.value) || (e.target.value.match(/\./g) || []).length > 1) {
      e.stopPropagation();
      return false;
    }
    doChange(e.target.value);
  };

  const onBlur = () => {
    if (!newValue) {
      doChange("0");
    }
  };

  useEffect(() => {
    if (isUpdated) {
      updateData(index, id, newValue || value || 0);
    }
    setIsUpdated(false);
  }, [newValue, value, updateData, index, id, isUpdated]);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return <Form.Control required value={newValue} onChange={onChange} onBlur={onBlur} />;
};

export default EditableCell;
