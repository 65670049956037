import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import AddressInput from "./Inputs/AddressInput";
import { Address } from "../types/addressType";
import AddressSelectCountry from "./Select/AddressSelectCountry";

type Props = {
  name: string;
  isEditing: boolean;
  address: Address;
  handleChange: (event: any) => Promise<void>;
  errors: Record<string, string>;
};

const CustomerAddress: React.FC<Props> = ({ name, isEditing, address, handleChange, errors }) => {
  const prefix = name[0];
  return (
    <>
      <Form.Group controlId="labelAddress">
        <Form.Label className="small font-weight-bold">{name}</Form.Label>
      </Form.Group>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="street"
          value={address.street}
          handleChange={handleChange}
          error={errors?.street}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="street_2"
          value={address.street_2}
          handleChange={handleChange}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="city"
          value={address.city}
          handleChange={handleChange}
          error={errors?.city}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          isTargetingUSA={address.country === "US"}
          prefix={prefix}
          name="stateProvince"
          value={address.stateProvince}
          handleChange={handleChange}
          error={errors?.stateProvince}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="zip"
          value={address.zip}
          handleChange={handleChange}
          error={errors?.zip}
        />
        <AddressSelectCountry
          isEditing={isEditing}
          prefix={prefix}
          name="country"
          value={address.country}
          handleChange={handleChange}
          error={errors?.country}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="firstName"
          value={address.firstName}
          handleChange={handleChange}
          error={errors?.firstName}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="lastName"
          value={address.lastName}
          handleChange={handleChange}
          error={errors?.lastName}
        />
      </Row>
    </>
  );
};

export default CustomerAddress;
