export enum Routes {
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:customerId",
  ORDERS = "/orders",
  ORDER = "/orders/:orderId",
  SUBSCRIPTIONS = "/subscriptions",
  SUBSCRIPTION = "/subscriptions/:subscriptionId",
  LOGIN = "/login",
  OAUTH2 = "/oauth2",
}

export const RouteTitles: { [route: string]: string } = Object.freeze({
  [Routes.CUSTOMERS]: "Customers List",
  [Routes.CUSTOMER]: "Customer",
  [Routes.ORDERS]: "Orders List",
  [Routes.ORDER]: "Shopify Orders",
  [Routes.SUBSCRIPTIONS]: "Subscription List",
  [Routes.SUBSCRIPTION]: "Subscription",
  [Routes.LOGIN]: "Login",
});

export const nameMappings = {
  [Routes.LOGIN]: [Routes.LOGIN],
  [Routes.OAUTH2]: [Routes.LOGIN],
  [Routes.CUSTOMERS]: [Routes.CUSTOMERS],
  [Routes.CUSTOMER]: [Routes.CUSTOMERS, Routes.CUSTOMER],
  [Routes.ORDERS]: [Routes.ORDERS],
  [Routes.ORDER]: [Routes.CUSTOMERS, Routes.CUSTOMER, Routes.ORDER],
  [Routes.SUBSCRIPTIONS]: [Routes.SUBSCRIPTIONS],
  [Routes.SUBSCRIPTION]: [Routes.CUSTOMERS, Routes.CUSTOMER, Routes.SUBSCRIPTION],
};
