export enum CountryCode {
  US = "US",
  UK = "UK",
  GB = "GB",
  CA = "CA",
}

export const CountryCodeSearchParams = new Map<CountryCode, number>([
  [CountryCode.US, 2],
  [CountryCode.UK, 3],
  [CountryCode.GB, 3],
  [CountryCode.CA, 11],
]);
