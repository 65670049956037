import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { ReactComponent as SaveIcon } from "../../icons/save.svg";

export interface SaveButtonProps extends ButtonProps {
  showIcon?: boolean;
  isDisabled?: boolean;
  isSaving: boolean;
  onSave: () => void;
  className?: string;
  style?: React.CSSProperties;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  showIcon = false,
  isDisabled = false,
  isSaving,
  onSave,
  className,
  style,
  ...buttonProps
}) => (
  <Button
    className={`font-weight-bold ${className || ""}${showIcon ?
      `d-inline-flex align-items-center font-weight-bold ${isSaving ? "justify-content-center" : ""}` : ""
    }`}
    style={{ width: 120, height: 40, ...style }}
    disabled={isDisabled || isSaving}
    onClick={onSave}
    {...buttonProps}
  >
    {isSaving ? (
      <Spinner as="span" animation="border" size="sm" role="status" />
    ) : (
      <>
        {showIcon && <SaveIcon className="mr-3" />}
        Save
      </>
    )}
  </Button>
);

export default SaveButton;
