import React from "react";
import Form from "react-bootstrap/Form";
import { CellProps } from "react-table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ProductAutocomplete from "../ProductAutocomplete";
import { SubscriptionItem } from "../../types/subscriptionTypes";

const IMAGE_SIZE = 30;
const IMAGE_CONTAINER_SIZE = 38;

const ProductCell = <T extends object>({
  value: { name, image },
  row: { index, original },
  column: { id },
  isEditing,
  updateData,
  country,
}: React.PropsWithChildren<CellProps<T>>): React.ReactElement<CellProps<T>> => {
  const item = original as SubscriptionItem;

  return (
    <div className="d-flex align-items-center">
      <div
        className="d-flex align-items-center justify-content-center border-gray-400 bg-white mr-1"
        style={{
          width: IMAGE_CONTAINER_SIZE,
          height: IMAGE_CONTAINER_SIZE,
          borderRadius: 10,
        }}
      >
        <img src={image} alt={name} className="rounded m-1" style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }} />
      </div>
      {(!isEditing && name) || (!item.main_product && !item.new_product) ? (
        <OverlayTrigger key="bottom" placement="bottom" overlay={<Tooltip id="tooltip-bottom">{name || ""}</Tooltip>}>
          <Form.Control required disabled className="border-0 bg-transparent" value={name || ""} />
        </OverlayTrigger>
      ) : (
        <ProductAutocomplete name={original} updateData={updateData} id={id} index={index} country={country} />
      )}
    </div>
  );
};

export default ProductCell;
