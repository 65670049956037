export const STATES = [
  { code: "AL", stateName: "Alabama" },
  { code: "AK", stateName: "Alaska" },
  { code: "AZ", stateName: "Arizona" },
  { code: "AR", stateName: "Arkansas" },
  { code: "CA", stateName: "California" },
  { code: "CO", stateName: "Colorado" },
  { code: "CT", stateName: "Connecticut" },
  { code: "DE", stateName: "Delaware" },
  { code: "DC", stateName: "District of Columbia" },
  { code: "FL", stateName: "Florida" },
  { code: "GA", stateName: "Georgia" },
  { code: "HI", stateName: "Hawaii" },
  { code: "ID", stateName: "Idaho" },
  { code: "IL", stateName: "Illinois" },
  { code: "IN", stateName: "Indiana" },
  { code: "IA", stateName: "Iowa" },
  { code: "KS", stateName: "Kansas" },
  { code: "KY", stateName: "Kentucky" },
  { code: "LA", stateName: "Louisiana" },
  { code: "ME", stateName: "Maine" },
  { code: "MD", stateName: "Maryland" },
  { code: "MA", stateName: "Massachusetts" },
  { code: "MI", stateName: "Michigan" },
  { code: "MN", stateName: "Minnesota" },
  { code: "MS", stateName: "Mississippi" },
  { code: "MO", stateName: "Missouri" },
  { code: "MT", stateName: "Montana" },
  { code: "NE", stateName: "Nebraska" },
  { code: "NV", stateName: "Nevada" },
  { code: "NH", stateName: "New Hampshire" },
  { code: "NJ", stateName: "New Jersey" },
  { code: "NM", stateName: "New Mexico" },
  { code: "NY", stateName: "New York" },
  { code: "NC", stateName: "North Carolina" },
  { code: "ND", stateName: "North Dakota" },
  { code: "OH", stateName: "Ohio" },
  { code: "OK", stateName: "Oklahoma" },
  { code: "OR", stateName: "Oregon" },
  { code: "PA", stateName: "Pennsylvania" },
  { code: "RI", stateName: "Rhode Island" },
  { code: "SC", stateName: "South Carolina" },
  { code: "SD", stateName: "South Dakota" },
  { code: "TN", stateName: "Tennessee" },
  { code: "TX", stateName: "Texas" },
  { code: "UT", stateName: "Utah" },
  { code: "VT", stateName: "Vermont" },
  { code: "VA", stateName: "Virginia" },
  { code: "WA", stateName: "Washington" },
  { code: "WV", stateName: "West Virginia" },
  { code: "WI", stateName: "Wisconsin" },
  { code: "WY", stateName: "Wyoming" },
];
