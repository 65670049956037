import React, { useEffect, useState } from "react";
import { useCombobox } from "downshift";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useAsyncDebounce } from "react-table";
import { SubscriptionItem } from "../types/subscriptionTypes";
import { productConstants } from "../constants/productConstants";
import { CountryCode, CountryCodeSearchParams } from "../types/countryTypes";

const menuStyles = {
  maxHeight: "180px",
  overflowY: "auto",
  margin: 0,
  borderTop: 0,
  background: "white",
  position: "absolute",
  zIndex: 1000,
  listStyle: "none",
  padding: 0,
  left: "auto",
  width: "250px",
};

type Props = {
  name?: any;
  updateData: any;
  id: string;
  index: number;
  country: CountryCode;
};

const ProductAutocomplete: React.FC<Props> = ({ name, updateData, id, index, country }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const items = useMappedState((state: any) => state.products.products);

  useEffect(() => {
    if (items) {
      setLoading(false);
    }
  }, [items]);

  const handleChange = useAsyncDebounce(async () => {
    if (value.length < 3) return;
    setLoading(true);
    dispatch({
      type: productConstants.GET_PRODUCTS_AUTOCOMPLETE,
      payload: {
        sku: value,
        store: CountryCodeSearchParams.get(country),
      },
    });
  }, 1000);

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } = useCombobox({
    items,
    selectedItem: name,
    itemToString: (item: SubscriptionItem | null | undefined) => (item && item.profile) || "",
    onSelectedItemChange: (changes) => {
      if (changes.inputValue) {
        setValue(changes.inputValue);
        updateData(index, id, changes);
      }
    },
    onInputValueChange: async ({ inputValue }) => {
      setValue(inputValue || "");
      await handleChange();
    },
  });

  return (
    <div {...getComboboxProps()}>
      <div className="d-flex">
        <Form.Control required value={name || ""} {...getInputProps()} />
        {loading && (
          <Spinner className="align-self-center ml-2" as="span" animation="border" size="sm" role="status" />
        )}
      </div>
      <ul {...getMenuProps()} style={menuStyles}>
        {isOpen &&
          items.map((item, i) => (
            <li
              style={{
                backgroundColor: highlightedIndex === i ? "#bde4ff" : "inherit",
                fontStyle: value === item.profile ? "bold" : "normal",
                color: loading ? "grey" : "inherit",
                cursor: "pointer",
              }}
              key={item.product_id}
              {...getItemProps({ item, index: i })}
            >
              {item.profile}
            </li>
          ))}
      </ul>
    </div>
  );
};

ProductAutocomplete.defaultProps = {
  name: "",
};

export default ProductAutocomplete;
