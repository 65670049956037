import { orderConstants } from "../constants/orderConstants";
import { OrderState, OrderStatus, OrderType, PaymentStatus } from "../types/orderTypes";
import { AddressState } from "../types/addressType";
import { Customer } from "../types/customerTypes";

const initialState: OrderState = {
  ordersList: [],
  order: {
    id: "",
    shopify_id: "",
    api: "",
    type: OrderType.UNKNOWN,
    typeName: "",
    orderDate: "",
    price: "",
    tax: "",
    taxAmount: 0,
    totalAmount: 0,
    store: "",
    shop_name: "",
    shopName: "",
    currency: "",
    customer: {} as Customer,
    payment: PaymentStatus.PAID,
    itemCount: 0,
    totalPrice: 0,
    shipping: 0,
    status: OrderStatus.APPROVED,
    statusName: "",
    shopifyOrderNotes: "",
    billingAddress: AddressState,
    shippingAddress: AddressState,
    items: [],
    updatedAt: "",
    updatedBy: "",
    note: "",
  },
  filters: {},
  pageCount: 1,
  page: 1,
  search: "",
  sortBy: [],
};

export default function orders(state: OrderState = initialState, action: any) {
  switch (action.type) {
    case orderConstants.SET_ORDER_LOADING:
      return {
        ...state,
        order: action.payload.order,
      };
    case orderConstants.SET_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    default:
      return state;
  }
}
