import { customerConstants } from "../constants/customerConstants";
import { CustomerState, CustomerStatusEnum } from "../types/customerTypes";
import { AddressState } from "../types/addressType";

const initialState: CustomerState = {
  customersList: [],
  customer: {
    id: "",
    billingAddress: AddressState,
    email: "",
    name: "",
    orderHistory: [],
    phone: "",
    shippingAddress: AddressState,
    shopifyId: "",
    status: CustomerStatusEnum.ACTIVE,
    subscriptions: [],
    cancelledSubscriptions: [],
    failedSubscriptions: [],
    pausedSubscriptions: [],
    expiredSubscriptions: [],
    updatedAt: "",
    createdAt: "",
    updatedBy: undefined,
  },
  pageCount: 1,
  page: 1,
  search: "",
  filters: {
    status: {},
    createdAt: null,
  },
  sortBy: {},
};

export default function customers(state: CustomerState = initialState, action: any) {
  switch (action.type) {
    case customerConstants.SET_ALL_CUSTOMERS:
      return {
        ...state,
        customersList: action.payload.customers,
        pageCount: action.payload.pageCount,
        page: action.payload.page,
      };
    case customerConstants.SET_CUSTOMER:
      return {
        ...state,
        customer: { ...state.customer, ...action.payload.customer },
      };
    case customerConstants.SEARCH_CUSTOMER:
      return {
        ...state,
        search: action.payload.search,
      };
    case customerConstants.SET_FILTER_CUSTOMER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case customerConstants.SET_SORT_CUSTOMER:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case customerConstants.SET_ACTIVE_SUBSCRIPTIONS:
      return {
        ...state,
        customer: {
          ...state.customer,
          subscriptions: action.payload.activeSubscriptions.sort(
            (a, b) => b.schedule.previous_billing_date - a.schedule.previous_billing_date
          ) || [],
        },
      };
    case customerConstants.SET_CANCELLED_SUBSCRIPTIONS:
      return {
        ...state,
        customer: { ...state.customer, cancelledSubscriptions: action.payload.cancelledSubscriptions || [] },
      };
    case customerConstants.SET_EXPIRED_SUBSCRIPTIONS:
      return {
        ...state,
        customer: { ...state.customer, expiredSubscriptions: action.payload.expiredSubscriptions || [] },
      };
    case customerConstants.SET_FAILED_SUBSCRIPTIONS:
      return {
        ...state,
        customer: { ...state.customer, failedSubscriptions: action.payload.failedSubscriptions || [] },
      };
    case customerConstants.SET_PAUSED_SUBSCRIPTIONS:
      return {
        ...state,
        customer: { ...state.customer, pausedSubscriptions: action.payload.pausedSubscriptions || [] },
      };
    case customerConstants.SET_ONE_TIME_PURCHASES:
      return {
        ...state,
        customer: {
          ...state.customer,
          purchases: action.payload.purchases || [],
          pagination: action.payload.pagination,
        },
      };
    case customerConstants.SET_PAGE_OTP:
      return {
        ...state,
        customer: { ...state.customer, page: action.payload.page || 1 },
      };
    default:
      return state;
  }
}
