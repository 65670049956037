import { subscriptionConstants } from "../constants/subscriptionConstants";
import {
  BillingFrequency,
  SubscriptionsState,
  SubscriptionStatus,
} from "../types/subscriptionTypes";
import { AddressState } from "../types/addressType";

const initialState: SubscriptionsState = {
  subscriptionsList: [],
  subscription: {
    id: "",
    tax: "",
    frequency: "",
    orderId: "",
    signupDate: new Date(),
    previousBillingDate: new Date(),
    nextBillingDate: new Date(),
    next_billing_date: new Date(),
    billingFrequency: BillingFrequency.MONTHLY,
    customerId: "",
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    totalPrice: "",
    currency: "",
    amount: "",
    status: SubscriptionStatus.ACTIVE,
    store: "",
    sku: "",
    summary: "",
    image: "",
    billingDate: "",
    startDate: "",
    orderAmount: "",
    statusName: "",
    taxAmount: "",
    totalAmount: "",
    billingAddress: AddressState,
    shippingAddress: AddressState,
    additional_products: [],
    details: [],
    data: [],
    productInfo: {},
    mainProduct: [],
  },
  downsellList: {},
  pageCount: 1,
  page: 1,
  search: "",
  filters: {
    status: {},
    nextBillingDate: null,
  },
  sortBy: [],
  pendingOrder: [],
  history: [],
};

export default function subscriptions(state: SubscriptionsState = initialState, action: any) {
  switch (action.type) {
    case subscriptionConstants.SET_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsList: action.payload.subscriptions,
        pageCount: action.payload.pageCount,
        page: action.payload.page,
      };
    case subscriptionConstants.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...action.payload.subscription,
          frequency: action.payload.subscription.billingFrequency,
          billingAddress: {
            ...action.payload.subscription.billingAddress,
            id: +action.payload.subscription.billingAddress.id,
          },
          shippingAddress: {
            ...action.payload.subscription.shippingAddress,
            id: +action.payload.subscription.shippingAddress.id,
          },
        },
      };
    case subscriptionConstants.SET_SUBSCRIPTION_ORDERS:
      return {
        ...state,
        pendingOrder: action.payload.subscription.pending_order_line_items || [],
        history: action.payload.subscription.order_history || [],
      };
    case subscriptionConstants.SET_FILTER_SUBSCRIPTION:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case subscriptionConstants.SEARCH_SUBSCRIPTIONS:
      return {
        ...state,
        search: action.payload.search,
      };
    case subscriptionConstants.SET_SORT_SUBSCRIPTIONS:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case subscriptionConstants.SET_PAGE_SUBSCRIPTION:
      return {
        ...state,
        page: action.payload.page,
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          status: {
            status: action.payload.status,
          },
        },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_FREQUENCY:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          billingFrequency: action.payload.frequency,
        },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_DATE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          billing: {
            ...state.subscription.billing,
            nextBillingDate: action.payload.nextBillingDate,
          },
        },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          mainProduct:
            action.payload.mainProduct !== undefined ? action.payload.mainProduct : state.subscription.mainProduct,
          additionalProducts:
            action.payload.additionalProducts !== undefined
              ? action.payload.additionalProducts : state.subscription.additionalProducts,
        },
      };
    case subscriptionConstants.UPDATE_ADDITIONAL_PRODUCTS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          additionalProducts: action.payload.subscription.additionalProducts,
        },
      };
    default:
      return state;
  }
}
