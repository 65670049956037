import React, { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import CustomerAddress from "./AddressForm";
import { Addresses } from "../types/addressType";

type Props = {
  addresses: Addresses;
  action: string;
  isEditing: boolean;
  isSaving: boolean;
  errors?: any;
};

const AddressesForm: React.FC<Props> = ({ addresses, action, isEditing, errors }) => {
  const dispatch = useDispatch();
  const { shippingAddress, billingAddress } = addresses;

  const handleShippingChange = useCallback(
    async (event) => {
      if (!shippingAddress || !billingAddress) return;
      if (shippingAddress.id === billingAddress.id) {
        shippingAddress.id = null;
      }
      const updatedCustomer = {
        ...addresses,
        shippingAddress: {
          ...shippingAddress,
          [event.target.name]: event.target.value,
        },
      };
      dispatch({
        type: action,
        payload: { updatedCustomer },
      });
    },
    [dispatch, action, addresses, shippingAddress, billingAddress]
  );

  const handleBillingChange = useCallback(
    async (event) => {
      if (!shippingAddress || !billingAddress) return;
      if (shippingAddress.id === billingAddress.id) {
        billingAddress.id = null;
      }
      const updatedCustomer = {
        ...addresses,
        billingAddress: {
          ...billingAddress,
          [event.target.name]: event.target.value,
        },
      };
      dispatch({
        type: action,
        payload: { updatedCustomer },
      });
    },
    [dispatch, action, addresses, billingAddress, shippingAddress]
  );

  return (
    shippingAddress && billingAddress && (
      <>
        <CustomerAddress
          name="Shipping Address"
          isEditing={isEditing}
          address={shippingAddress}
          handleChange={handleShippingChange}
          errors={errors?.shippingAddress}
        />
        <CustomerAddress
          name="Billing Address"
          isEditing={isEditing}
          address={billingAddress}
          handleChange={handleBillingChange}
          errors={errors?.billingAddress}
        />
      </>
    )
  );
};

export default AddressesForm;
