import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import CustomersTable from "../components/Tables/CustomersTable";
import { CustomerState } from "../types/customerTypes";
import { isPetlabUser } from "../utilities/petlabUser";

const Customers = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState("");
  const customersState: CustomerState = useMappedState((state): any => state.customers);
  const customers = customersState.customersList;
  const { pageCount } = customersState;
  const { search } = customersState;
  const [page, setPage] = useState(customersState.page);

  useEffect(() => {
    if ((page && isPetlabUser(search)) || search || sortBy) {
      dispatch({
        type: "GET_ALL_CUSTOMERS",
        payload: {
          page: page || 1,
          search,
          sortBy,
        },
      });
    }
  }, [dispatch, page, search, sortBy]);

  const sortByForTable = useCallback((sortByValue) => {
    const sorting: any = {};
    sortByValue.forEach((value: any) => {
      sorting[!value.desc ? "asc" : "desc"] = [value.id];
    });
    setSortBy(sorting);
  }, []);

  return (
    <div className="d-flex flex-column overflow-hidden p-3 min-height-400">
      <div className="mb-3 d-flex justify-content-between">
        <h3>Customers</h3>
      </div>
      <CustomersTable
        customers={customers}
        pageCount={pageCount}
        search={search}
        setPage={setPage}
        page={page}
        sortByForTable={sortByForTable}
      />
    </div>
  );
};

export default Customers;
