import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ReactComponent as BackIcon } from "../icons/leftArrow.svg";
import SaveButton from "../components/Buttons/SaveButton";
import EditButton from "../components/Buttons/EditButton";
import CancelButton from "../components/Buttons/CancelButton";
import NavConfirmationButton from "../components/Buttons/NavConfirmationButton";
import { Routes } from "../constants/routes";
import {
  DifferencesType,
  SubscriptionItem,
  SubscriptionStatus,
} from "../types/subscriptionTypes";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { getCorrectFormat, getNextBillingDateFormat, getSimpleDateFormat } from "../utilities/date";
import CancelModal from "../components/Modals/CancelModal";
import RestartModal from "../components/Modals/RestartModal";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import { compareObjects } from "../utilities/compareObjects";
import { SubscriptionInfoBlock } from "../components/SubscriptionBlocks/SubscriptioInfoBlock";

const Subscription: React.FC = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const nextDate = getCorrectFormat(date.getTime());
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [cancelReason, setCancelReason] = useState(0);
  const [cancelMessage, setCancelMessage] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [showDownsellModal, setShowDownsellModal] = useState(false);
  const [newDate, setNewDate] = useState(nextDate);

  const dispatch = useDispatch();

  const subscription = useMappedState((state): any => state.subscriptions.subscription);
  const [initialSubscription, setInitialSubscription] = useState(subscription);
  const regionError = useMappedState((state): any => (
    state.errors.errorMessage === "Product store/region does not match subscription store/region"));
  const isLoading = useMappedState((state): any => state.loading.isLoading);
  const isLoadingAdditionalProducts = useMappedState((state): any => (
    state.loading.isLoadingSubscriptionAdditionalProducts
  ));

  const getSubscription = () => {
    dispatch({
      type: "GET_SUBSCRIPTION",
      payload: subscriptionId,
    });
  };

  const getSubscriptionOrders = () => {
    dispatch({
      type: "GET_SUBSCRIPTION_ORDERS",
      payload: subscriptionId,
    });
  };

  useEffect(() => {
    getSubscription();
    getSubscriptionOrders();
  }, [subscriptionId, regionError]);

  useEffect(() => {
    setInitialSubscription(subscription);
  }, [subscription.id]);

  useEffect(() => {
    const result = initialSubscription.additionalProducts &&
      initialSubscription.additionalProducts.every((one) => one.product_id && !one.new_product);
    setIsDisabled(!result);
  }, [initialSubscription.additionalProducts]);

  useEffect(() => {
    const notSavedItems = initialSubscription?.additionalProducts?.filter((item) => item.new_product);

    if (notSavedItems?.length) {
      setInitialSubscription({
        ...subscription,
        additionalProducts: subscription.additionalProducts!.concat(notSavedItems),
      });
    } else {
      setInitialSubscription({
        ...subscription,
      });
    }
  }, [subscription.additionalProducts]);

  const onEdit = () => {
    subscription.additionalProducts = initialSubscription.additionalProducts;
    setInitialSubscription(subscription);
    setIsEditing(true);
  };

  const onCancel = () => {
    setInitialSubscription(subscription);
    setIsEditing(false);
    getSubscription();
    getSubscriptionOrders();
  };

  const onCancelSubscription = (subId: string | number) => {
    setShowCancelModal(false);
    setIsSaving(false);
    setIsEditing(false);
    dispatch({
      type: "CANCEL_SUBSCRIPTION",
      payload: {
        id: subId,
        data: {
          cancellationReason: cancelReason,
          otherElaboration: cancelMessage,
        },
      },
    });
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
      payload: {
        status: SubscriptionStatus.CANCELED,
      },
    });
  };

  const onDownsell = (id: string, customerId?: string) => {
    setShowDownsellModal(false);
    dispatch({
      type: "DOWNSELL_SUBSCRIPTION",
      payload: {
        id,
        body: {
          customer_id: customerId,
          cancellationReason: 28,
        },
      },
    });
  };

  const formatProduct = ({ price, product_id, quantity }) => {
    const formattedPrice = parseFloat(price) || 0;
    return {
      id: +product_id,
      price: +formattedPrice.toFixed(2),
      quantity: +quantity,
    };
  };

  const getBillingFrequencyKey = useCallback((frequency) => {
    if (!frequency?.schedule_type) return frequency;
    return `${frequency.schedule_value}:${frequency.schedule_type}`;
  }, []);

  const formatSubscription = (sub) => ({
    frequency: getBillingFrequencyKey(sub?.billingFrequency),
    nextBillingDate: getNextBillingDateFormat(sub?.billing?.nextBillingDate),
    billingAddress: { ...sub?.billingAddress },
    shippingAddress: { ...sub?.shippingAddress },
    mainProduct: sub?.mainProduct[0] ? formatProduct(sub.mainProduct[0]) : {},
  });

  const detectChanges = (current, initial) => {
    const currentFormatted = formatSubscription(current);
    const initialFormatted = formatSubscription(initial);
    return compareObjects(initialFormatted, currentFormatted, "subscription");
  };

  const handleDispatch = (changedValues) => {
    if (Object.keys(changedValues).length > 0) {
      dispatch({
        type: subscriptionConstants.UPDATE_SUBSCRIPTION,
        payload: {
          id: subscription?.id,
          data: changedValues,
        },
      });

      if (
        JSON.stringify(changedValues.mainProduct) !==
        JSON.stringify(formatSubscription(initialSubscription).mainProduct)
      ) {
        getSubscriptionOrders();
      }
    }
  };

  const saveRow = (item: SubscriptionItem) => {
    const updatedItem = {
      ...item,
      new_product: false,
    };

    dispatch({
      type: subscriptionConstants.ADD_SUBSCRIPTION_ADDITIONAL_PRODUCTS,
      payload: {
        subscriptionId: subscription.id,
        data: {
          product_id: +item.product_id,
          quantity: +item.quantity,
          item_price: +item.price,
        },
      },
    });

    const index = initialSubscription.additionalProducts.findIndex((i) => +i.product_id === +updatedItem.product_id
      && !i.additional_product_id);

    if (index !== -1) {
      setInitialSubscription((prevState) => ({
        ...prevState,
        additionalProducts: [
          ...prevState.additionalProducts.slice(0, index),
          updatedItem,
          ...prevState.additionalProducts.slice(index + 1),
        ],
      }));
    }
  };

  function handleAdditionalProductsChanges(initial, current) {
    const initialProducts = initial.additionalProducts || [];
    const currentProducts = current.additionalProducts || [];

    if (currentProducts.length < initialProducts.length) {
      initialProducts.forEach((product) => {
        if (product.new_product) {
          saveRow(product);
        }
      });
    } if (
      JSON.stringify(currentProducts) !== JSON.stringify(initialProducts)
    ) {
      initialProducts.forEach((product) => {
        const currentProduct = currentProducts.find((currentItem) => +currentItem.product_id === +product.product_id);
        if (currentProduct) {
          const data: DifferencesType = {};
          if (+currentProduct.price !== +product.price
            || +currentProduct.quantity !== +product.quantity
          ) {
            data.item_price = +product.price;
            data.quantity = +product.quantity;
          }

          if (Object.keys(data).length > 0) {
            dispatch({
              type: subscriptionConstants.UPDATE_SUBSCRIPTION_ADDITIONAL_PRODUCTS,
              payload: {
                subscriptionId: current.id,
                additionalProductId: product.additional_product_id,
                data,
              },
            });
          }
        }
      });
    }
  }

  const onSave = useCallback(async () => {
    setIsSaving(true);
    handleAdditionalProductsChanges(initialSubscription, subscription);

    const changedValues = detectChanges(subscription, initialSubscription);

    if (changedValues) {
      handleDispatch(changedValues);
    }

    setIsSaving(false);
    setIsEditing(false);
  }, [subscription, initialSubscription, dispatch]);

  const { id, customer } = subscription;

  const onRestartSubscription = () => {
    setShowRestartModal(false);
    setIsSaving(false);
    setIsEditing(false);
    dispatch({
      type: subscriptionConstants.RESTART_SUBSCRIPTION,
      payload: {
        id: subscriptionId,
        data: {
          new_rebill_date: getSimpleDateFormat(newDate),
          quantity: subscription.mainProduct[0].quantity,
          frequency: `${subscription.frequency.schedule_value}:${subscription.frequency.schedule_type}`,
        },
      },
    });
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
      payload: {
        status: SubscriptionStatus.ACTIVE,
      },
    });

    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_DATE,
      payload: {
        nextBillingDate: getNextBillingDateFormat(newDate),
      },
    });
  };

  const renderBody = () => (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          <NavConfirmationButton
            route={`${Routes.CUSTOMERS}/${customer.id}`}
            isEditing={isEditing}
            isSaving={isSaving}
            onSave={onSave}
          >
            <BackIcon />
            Return to Customer
          </NavConfirmationButton>
          <h4 className="m-0">{`Subscription #${id}`}</h4>
        </div>
        {isEditing ? (
          <div>
            <CancelButton
              style={{ width: 120, height: 48 }}
              isSaving={isSaving}
              onCancel={onCancel}
            />
            <SaveButton
              showIcon
              style={{ width: 120, height: 48 }}
              isSaving={isSaving}
              isDisabled={isDisabled}
              onSave={onSave}
            />
          </div>
        ) : (
          <EditButton showIcon style={{ width: 120, height: 48 }} onEdit={onEdit} />
        )}
      </div>
      <SubscriptionInfoBlock
        isSaving={isSaving}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        initialSubscription={initialSubscription}
        setInitialSubscription={setInitialSubscription}
        saveRow={saveRow}
        onCancelSubscription={onCancelSubscription}
        setCancelMessage={setCancelMessage}
        setShowCancelModal={setShowCancelModal}
        setShowRestartModal={setShowRestartModal}
        setShowDownsellModal={setShowDownsellModal}
        cancelReason={setShowDownsellModal}
        setCancelReason={setCancelReason}
      />
      <div className="bottom-bar d-flex align-items-center justify-content-end px-5">
        {isEditing ? (
          <div>
            <CancelButton isSaving={isSaving} onCancel={onCancel} />
            <SaveButton isSaving={isSaving} onSave={onSave} />
          </div>
        ) : (
          <EditButton onEdit={onEdit} />
        )}
      </div>
      <CancelModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        subscriptionId={subscription}
        setCancelReason={setCancelReason}
        setCancelMessage={setCancelMessage}
        cancelSubscription={onCancelSubscription}
        cancelReason={cancelReason}
      />
      <RestartModal
        show={showRestartModal}
        onHide={() => setShowRestartModal(false)}
        subscription={subscription.id}
        handleRestart={onRestartSubscription}
        currentDate={date}
        setNewDate={setNewDate}
      />
      <ConfirmationModal
        show={showDownsellModal}
        onHide={() => setShowDownsellModal(false)}
        subscription={subscription}
        downsellSubscription={() => onDownsell(id, customer.id)}
        confirmText="active a downsell"
      />
      {
        (isLoading || isLoadingAdditionalProducts) && (
          <div className="centered-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )
      }
    </>
  );

  return (
    <>
      {!id || +subscriptionId !== +id ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Subscription;
