import { all, put, takeEvery, call } from "redux-saga/effects";
import subscriptionService from "../services/subscriptionService";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { errorWorker } from "../utilities/errors";
import { successWorker } from "../utilities/success";
import {
  clearLoading,
  clearLoadingSubOrders,
  clearLoadingSubscriptionAdditionalProducts,
  setLoading, setLoadingSubOrders,
  setLoadingSubscriptionAdditionalProducts,
} from "../utilities/loading";
import { successConstants } from "../constants/successConstants";

function* getSubscription(id: any) {
  try {
    yield put(setLoading());
    const { data }: any = yield subscriptionService.getSubscription(id.payload);
    if (!data.data.billingAddress) {
      data.data.billingAddress = {
        id: "",
        firstName: "Undefined",
        lastName: "Undefined",
        phone: data.data.customer.phone,
        street: "",
        street_2: "",
        city: "",
        country: "",
        zip: "",
        stateProvince: "",
      };
    }

    if (!data.data.shippingAddress) {
      data.data.shippingAddress = {
        id: "",
        firstName: "Undefined",
        lastName: "Undefined",
        phone: data.data.customer.phone,
        street: "",
        street_2: "",
        city: "",
        country: "",
        zip: "",
        stateProvince: "",
      };
    }
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription: data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoading());
  }
}

function* getSubscriptionOrders(id: any) {
  try {
    yield put(setLoadingSubOrders());
    const { data }: any = yield subscriptionService.getSubscriptionOrders(id.payload);

    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION_ORDERS,
      payload: {
        subscription: data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingSubOrders());
  }
}

function* updateSubscription(props: any): any {
  try {
    yield put(setLoading());
    const subscription: any = yield subscriptionService.updateSubscription(props.payload);

    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription: subscription.data.data.subscriptions,
        status: "success",
      },
    });

    if (props.payload.data.mainProduct) {
      yield put({
        type: "GET_SUBSCRIPTION_ORDERS",
        payload: +subscription.data.data.subscriptions.id,
      });
    }
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoading());
  }
}

function* updateSubscriptionAddress(props: any): any {
  try {
    const subscription: any = props.payload.updatedCustomer;
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* downsellSubscription(props: any): any {
  try {
    yield put(setLoading());
    yield subscriptionService.downsellSubscription({
      id: props.payload.id,
      body: props.payload.body,
    });

    yield put(successWorker({
      status: true,
      data: { message: "The downsell offer has been activated" },
    }));

    yield put({
      type: subscriptionConstants.GET_SUBSCRIPTION,
      payload: props.payload.id,
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoading());
  }
}

function* cancelSubscription(props: any): any {
  try {
    yield put(setLoading());
    yield subscriptionService.cancelSubscription(props.payload);
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoading());
  }
}

function* restartSubscription(props: any): any {
  try {
    yield put(setLoading());
    yield subscriptionService.restartSubscription(props.payload);
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoading());
  }
}

function* addSubscriptionAdditionalProducts(props: any): any {
  try {
    yield put(setLoadingSubscriptionAdditionalProducts());
    const { data }: any = yield subscriptionService.addSubscriptionAdditionalProducts(props.payload);
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription: data.data.subscriptions,
        status: "success",
      },
    });

    yield put({
      type: subscriptionConstants.GET_SUBSCRIPTION,
      payload: props.payload.subscriptionId,
    });

    yield call(getSubscriptionOrders, { payload: props.payload.subscriptionId });

    yield put({
      type: successConstants.SET_SUCCESS,
      payload: {
        successStatus: 200,
        successMessage: "Product added successfully",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingSubscriptionAdditionalProducts());
  }
}

function* updateSubscriptionAdditionalProducts(props: any): any {
  try {
    yield put(setLoadingSubscriptionAdditionalProducts());
    const { data }: any = yield subscriptionService.updateSubscriptionAdditionalProducts(props.payload);
    yield put({
      type: subscriptionConstants.UPDATE_ADDITIONAL_PRODUCTS,
      payload: {
        subscription: data.data.subscriptions,
        status: "success",
      },
    });
    yield call(getSubscriptionOrders, { payload: props.payload.subscriptionId });

    yield put({
      type: successConstants.SET_SUCCESS,
      payload: {
        successStatus: 200,
        successMessage: "Product updated successfully",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingSubscriptionAdditionalProducts());
  }
}

function* deleteSubscriptionAdditionalProducts(props: any): any {
  try {
    yield put(setLoadingSubscriptionAdditionalProducts());
    yield subscriptionService.deleteSubscriptionAdditionalProducts(props.payload);
    const { data }: any = yield subscriptionService.getSubscription(props.payload.subscriptionId);
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription: data.data,
        status: "success",
      },
    });

    yield call(getSubscriptionOrders, { payload: props.payload.subscriptionId });

    yield put({
      type: successConstants.SET_SUCCESS,
      payload: {
        successStatus: 200,
        successMessage: "Product deleted successfully",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingSubscriptionAdditionalProducts());
  }
}

function* subscriptionWatcher() {
  yield takeEvery(subscriptionConstants.GET_SUBSCRIPTION, getSubscription);
  yield takeEvery(subscriptionConstants.GET_SUBSCRIPTION_ORDERS, getSubscriptionOrders);
  yield takeEvery(subscriptionConstants.UPDATE_SUBSCRIPTION, updateSubscription);
  yield takeEvery(subscriptionConstants.UPDATE_SUBSCRIPTION_ADDRESS, updateSubscriptionAddress);
  yield takeEvery(subscriptionConstants.CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeEvery(subscriptionConstants.DOWNSELL_SUBSCRIPTION, downsellSubscription);
  yield takeEvery(subscriptionConstants.RESTART_SUBSCRIPTION, restartSubscription);
  yield takeEvery(subscriptionConstants.ADD_SUBSCRIPTION_ADDITIONAL_PRODUCTS, addSubscriptionAdditionalProducts);
  yield takeEvery(subscriptionConstants.UPDATE_SUBSCRIPTION_ADDITIONAL_PRODUCTS, updateSubscriptionAdditionalProducts);
  yield takeEvery(subscriptionConstants.DELETE_SUBSCRIPTION_ADDITIONAL_PRODUCTS, deleteSubscriptionAdditionalProducts);
}

export default function* rootsubscriptionSaga() {
  yield all([subscriptionWatcher()]);
}
