export const getCorrectFormat = (time: number | Date) => {
  if (!time || typeof time !== "number") return 0;
  return time < 1e12 ? time * 1000 : time;
};

export function getDateFormat(date: number | Date): string {
  const correctedDate = getCorrectFormat(date);
  if (correctedDate < 0) return "N/A";
  const dateParse: Date = new Date(correctedDate);
  return dateParse.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
}

export function getDateAtTimeFormat(date: number | Date): string {
  let correctedDate = date;
  if (typeof date !== "number") correctedDate = new Date(date).getTime();
  correctedDate = getCorrectFormat(correctedDate);
  if (!correctedDate) return "N/A";
  const dateParse: Date = new Date(correctedDate);
  return `${dateParse.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })} at ${dateParse.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })}`;
}

export function getDateAtTimeFormatString(date: any): string {
  if (!date) {
    return "N/A";
  }

  const dateParse: Date = new Date(date);
  return `${dateParse.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })} at ${dateParse.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })}`;
}

export function getSimpleDateFormat(dateInput: any): string {
  if (!dateInput) {
    return "N/A";
  }
  const date = getCorrectFormat(dateInput);
  return new Date(date).toISOString().slice(0, 10);
}

export function getNextBillingDateFormat(dateInput: Date | number): string {
  if (!dateInput) {
    return "N/A";
  }

  const dateObject = (dateInput instanceof Date) ? dateInput : new Date(dateInput);
  const dateInUTC = dateObject.toISOString().substring(0, 10);
  const timeInUTC = dateObject.toISOString().substring(11, 19);

  return `${dateInUTC} ${timeInUTC}`;
}
