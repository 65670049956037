import React, { useEffect, useMemo, useState } from "react";
import { Column, CellProps } from "react-table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import DetailsTable from "./DetailsTable";
import EditableCell from "./TableCells/EditableCell";
import ProductCell from "./TableCells/ProductCell";
import DeleteRowCell from "./TableCells/DeleteRowCell";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import { transformSubscriptionItems } from "../utilities/product";
import calculate from "../utilities/calculator";
import NumberEditableCell from "./TableCells/NumberEditableCell";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { Subscription, SubscriptionItem } from "../types/subscriptionTypes";
import { CurrencyType } from "../types/currencyType";
import HeadCell from "./Tables/HeadCell";
import SaveRowCell from "./TableCells/SaveRowCell";
import SaveModal from "./Modals/SaveModal";

export interface SubscriptionDetailsTableProps {
  initialSubscription: Subscription;
  isEditing?: boolean;
  isSaving?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  setInitialSubscription?: (initialSubscription: Subscription) => void;
  saveRow?: (item: SubscriptionItem) => void;
  header?: React.ReactNode;
  showFooter?: boolean;
  className?: string;
  country?: string;
}

const SubscriptionDetailsTable: React.FC<SubscriptionDetailsTableProps> = ({
  initialSubscription,
  isEditing = false,
  isSaving = false,
  saveRow,
  setIsEditing,
  setInitialSubscription,
  header,
  showFooter = false,
  country,
  ...forwardProps
}) => {
  const dispatch = useDispatch();
  const [totalTax, setTotalTax] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderFullAmount, setOrderFullAmount] = useState(0);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [additionalProductId, setAdditionalProductId] = useState(0);
  const subscription = useMappedState((state): any => state.subscriptions.subscription);

  const details: any = useMemo(() => {
    if (initialSubscription.line_items) return initialSubscription.line_items;
    return initialSubscription.mainProduct?.concat(initialSubscription.additionalProducts ?? []) || [];
  }, [initialSubscription.mainProduct, initialSubscription.line_items, initialSubscription.additionalProducts]);

  const columns: Column<SubscriptionItem>[] = useMemo(
    () => [
      {
        Header: HeadCell("No."),
        id: "index",
        Cell: ({ row }: CellProps<SubscriptionItem>) => row.index + 1,
      },
      {
        Header: HeadCell("SKU"),
        accessor: "sku",
      },
      {
        Header: HeadCell("Product"),
        accessor: "productInfo",
        Cell: ProductCell,
      },
      {
        Header: HeadCell("Qty"),
        accessor: "quantity",
        ...(isEditing && !isSaving && { Cell: NumberEditableCell }),
      },
      {
        Header: HeadCell("Price"),
        accessor: "price",
        Cell:
          isEditing && !isSaving
            ? EditableCell
            : ({ value }) => `${CurrencyType[initialSubscription.currency || "USD"]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Tax"),
        accessor: "tax",
        Cell: ({ value }) => `${CurrencyType[initialSubscription.currency || "USD"]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Total"),
        accessor: initialSubscription.details ? "total" : "totalAmount",
        Cell: ({ value }) => `${CurrencyType[initialSubscription.currency || "USD"]}${Number(value).toFixed(2)}`,
      },
      ...(isEditing && !isSaving ? [{ id: "deleteButton", minWidth: 10, Cell: DeleteRowCell }] : []),
      ...(isEditing && !isSaving ? [{ id: "saveButton", minWidth: 10, Cell: SaveRowCell }] : []),
    ],
    [isEditing, isSaving, initialSubscription.currency]
  );

  const updateItems = (rowIndex, columnId, value) => {
    if (columnId === "productInfo") {
      if (details[rowIndex].main_product) {
        value.selectedItem.main_product = true;
      }
      if (details[rowIndex].new_product) {
        value.selectedItem.new_product = true;
      }
      details[rowIndex] = value.selectedItem;
    }
    calculate(initialSubscription, details, rowIndex, columnId, value);

    const updatedDetails = details.map((item, index) => (
      index === rowIndex ? { ...item, [columnId]: value } : item
    ));

    const main = updatedDetails.filter((item) => item.main_product);
    const additional = updatedDetails.filter((item) => !item.main_product);

    if (!setInitialSubscription) return;

    setInitialSubscription({
      ...initialSubscription,
      mainProduct: main,
      additionalProducts: additional,
    });
  };

  const addRow = () => {
    const subscriptionItem: any = {
      product_id: 0,
      image: "/images/product-placeholder.jpg",
      price: 0,
      quantity: 1,
      sku: "",
      tax_amount: 0,
      tax: 0,
      product_name: "",
      row_total: 0,
      productInfo: {},
      new_product: true,
      main_product: false,
    };

    if (!setInitialSubscription) return;

    setInitialSubscription({
      ...initialSubscription,
      additionalProducts: [...initialSubscription.additionalProducts!, subscriptionItem],
    });
  };

  const deleteRow = (id: number, index: number) => {
    if (!id || Number.isNaN(id)) {
      const updatedDetails = details.filter((v, k: number) => k !== index);
      const main = updatedDetails.filter((item) => item.main_product);
      const additional = updatedDetails.filter((item) => !item.main_product);

      if (!setInitialSubscription) return;

      setInitialSubscription({
        ...subscription,
        mainProduct: main,
        additionalProducts: additional,
      });
    } else {
      setAdditionalProductId(id);
      setShowSaveModal(true);
    }

    dispatch({
      type: "GET_SUBSCRIPTION",
      payload: subscription.id,
    });
  };

  const deleteProduct = (id) => {
    dispatch({
      type: subscriptionConstants.DELETE_SUBSCRIPTION_ADDITIONAL_PRODUCTS,
      payload: {
        subscriptionId: initialSubscription.id,
        additionalProductId: id,
      },
    });

    if (!setInitialSubscription) return;

    setInitialSubscription({
      ...initialSubscription,
      additionalProducts: initialSubscription.additionalProducts!.filter((item) => item.additional_product_id !== id),
    });
  };

  useEffect(() => {
    if (details) {
      const totalTaxAmount = (details as any[]).reduce(
        (prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.tax_amount || cur.taxAmount || 0), 0
      );
      setTotalTax(totalTaxAmount);

      const totalPriceAmount = (details as any[]).reduce(
        (prev: number, cur) => Number(prev) + Number(+cur.price),
        0
      );
      setTotalPrice(totalPriceAmount);

      const totalOrderAmount = (details as any[]).reduce(
        (prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.row_total || cur.total || cur.totalPrice),
        0
      );
      setOrderFullAmount(totalOrderAmount);
    }
  }, [initialSubscription, initialSubscription.line_items]);

  const data = useMemo(() => transformSubscriptionItems(details), [
    initialSubscription, details, initialSubscription.line_items,
  ]);

  return (
    <>
      <div {...forwardProps}>
        {header}
        <Row>
          <Col>
            <DetailsTable
              columns={columns}
              data={data}
              isEditing={isEditing}
              isSaving={isSaving}
              updateData={updateItems}
              deleteRow={deleteRow}
              saveRow={saveRow}
              country={country}
            />
          </Col>
        </Row>
        {showFooter && (
          <Row className="flex-wrap-reverse">
            <Col md={6} xl={8}>
              {isEditing && (
                <Button variant="link" className="font-size-sm d-flex align-items-center" onClick={addRow}>
                  <AddIcon className="icon-primary mr-2" />
                  Add Product
                </Button>
              )}
            </Col>
            <Col md={2} xl={2} className="text-right">
              <h6 className="m-0 small font-weight-bold">Total Item Price</h6>
              <p>
                {CurrencyType[initialSubscription.currency]}
                {initialSubscription.amount || totalPrice.toFixed(2)}
              </p>
            </Col>
            <Col md={2} xl={1} className="text-right">
              <h6 className="m-0 small font-weight-bold">Total Tax</h6>
              <p>
                {CurrencyType[initialSubscription.currency]}
                {initialSubscription.taxAmount || totalTax.toFixed(2)}
              </p>
            </Col>
            <Col md={2} xl={1} className="text-right">
              <h6 className="m-0 small font-weight-bold">Total Amount</h6>
              <p>
                {CurrencyType[initialSubscription.currency]}
                {initialSubscription.totalPrice || initialSubscription.totalAmount || orderFullAmount.toFixed(2)}
              </p>
            </Col>
          </Row>
        )}
      </div>
      <SaveModal
        show={showSaveModal}
        onHide={() => setShowSaveModal(false)}
        itemId={additionalProductId}
        handleItem={deleteProduct}
        confirmText="remove product #"
      />

    </>
  );
};

export default SubscriptionDetailsTable;
