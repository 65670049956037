import React, { useMemo } from "react";
import { Column, Row } from "react-table";
import { useHistory } from "react-router-dom";
import {
  OrderStatus,
  OrderStatusDisplayName,
  OrderTableData,
  // OrderType,
  // OrderTypeDisplayName,
} from "../../types/orderTypes";
import { getDateAtTimeFormatString } from "../../utilities/date";
import MultiSelectFilter from "../MultiSelectFilter";
import { getEnumDisplayNames } from "../../utilities/enum";
import Table from "./Table";
import DateSelectFilter from "../DateSelectFilter";

const OrderStatusFilter = MultiSelectFilter({
  options: getEnumDisplayNames(OrderStatus, OrderStatusDisplayName),
  toggleText: "Status",
  filterName: "statusName",
  store: "orders",
  action: "SET_FILTER_ORDER",
});
// const OrderTypeFilter = MultiSelectFilter({
//   options: getEnumDisplayNames(OrderType, OrderTypeDisplayName),
//   toggleText: "Type",
//   filterName: "type",
//   action: "SET_FILTER_ORDER",
//   store: "orders",
// });
const OrderDateFilter = DateSelectFilter({
  filterName: "orderDate",
  action: "SET_FILTER_ORDER",
  store: "orders",
});

const columnFilterIds = ["vendor", "orderDate", "statusName", "type"];

type Props = {
  orders: OrderTableData[];
  page: number;
  pageCount: number;
  search: string;
  setPage: (page: number) => void;
  sortByForTable: (sortBy: string) => void;
};

const OrdersTable: React.FC<Props> = ({ orders, page, pageCount, search, setPage, sortByForTable }) => {
  // const match = useRouteMatch();
  const history = useHistory();

  const columns: Column<OrderTableData>[] = useMemo(
    () => [
      { Header: "ID", id: "index", Cell: ({ row }: any) => row.index + 1 },
      {
        Header: "ORDER",
        accessor: "shopifyName",
        Cell: ({ value }) => <div style={{ color: "#49aaef" }}>{value}</div>,
      },
      {
        Header: "DATE",
        accessor: "orderDate",
        Cell: ({ value }) => getDateAtTimeFormatString(value),
        Filter: OrderDateFilter,
      },
      { Header: "CUSTOMER", accessor: "customerName" },
      { Header: "EMAIL", accessor: "customerEmail" },
      { Header: "PRICE", accessor: "price" },
      { Header: "TAX", accessor: "taxAmount" },
      { Header: "TOTAL", accessor: "totalAmount" },
      {
        Header: "STATUS",
        accessor: "statusName",
        Filter: OrderStatusFilter,
        filter: "includesSome",
      },
    ],
    []
  );

  const onRowClick = (row: Row<OrderTableData>["original"]) => {
    history.push(`/orders/${row.id}`);
  };

  return (
    <Table
      columns={columns}
      data={orders}
      columnFilterIds={columnFilterIds}
      onRowClick={onRowClick}
      searchPlaceholder="Filter orders..."
      pageCount={pageCount}
      handlePage={setPage}
      currentPage={page}
      search={search}
      searchActionName="SEARCH_ORDER"
      sortByForTable={sortByForTable}
    />
  );
};

export default OrdersTable;
