import React, { useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Column, Row } from "react-table";
import MultiSelectFilter from "../MultiSelectFilter";
import {
  BillingFrequency,
  BillingFrequencyDisplayName,
  SubscriptionStatusDisplayName,
  SubscriptionTableData,
} from "../../types/subscriptionTypes";
import { getDateAtTimeFormatString } from "../../utilities/date";
import Table from "./Table";
import formatPhoneNumber from "../../utilities/phone";
import DateSelectFilter from "../DateSelectFilter";
import ProductCell from "../TableCells/ProductCell";

const SubscriptionStatusFilter = MultiSelectFilter({
  options: ["Active", "Canceled"],
  toggleText: "Status",
  filterName: "status",
  action: "SET_FILTER_SUBSCRIPTION",
  store: "subscriptions",
});
const SubscriptionDateFilter = DateSelectFilter({
  filterName: "nextBillingDate",
  action: "SET_FILTER_SUBSCRIPTION",
  store: "subscriptions",
});
const columnFilterIds = ["nextBillingDate", "status"];

type Props = {
  subscriptions: SubscriptionTableData[];
  page: number;
  pageCount: number;
  search: string;
  setPage: (page: number) => void;
  sortByForTable: (sortBy: string) => void;
};

const SubscriptionsTable: React.FC<Props> = ({ subscriptions, page, pageCount, search, setPage, sortByForTable }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const columns: Column<SubscriptionTableData>[] = useMemo(
    () => [
      { Header: "ID", id: "index", Cell: ({ row }: any) => row.index + 1 },
      {
        Header: "SUBSCRIPTION",
        accessor: "id",
        Cell: ({ value }) => <div style={{ color: "#49aaef" }}>#{value}</div>,
      },
      {
        Header: "PRODUCT",
        accessor: "product",
        Cell: ProductCell,
      },
      {
        Header: "FREQUENCY",
        accessor: "frequency",
        Cell: ({ value }) => BillingFrequencyDisplayName.get(value.toLowerCase() as BillingFrequency),
      },
      {
        Header: "NEXT BILLING",
        accessor: "nextBillingDate",
        Cell: ({ value }) => getDateAtTimeFormatString(value),
        Filter: SubscriptionDateFilter,
      },
      { Header: "CUSTOMER", accessor: "customerName" },
      { Header: "EMAIL", accessor: "customerEmail" },
      {
        Header: "PHONE",
        accessor: "customerPhone",
        Cell: ({ value }) => formatPhoneNumber(value),
      },
      {
        Header: "TOTAL",
        accessor: "totalPrice",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value }) => SubscriptionStatusDisplayName.get(Number(value)) || null,
        Filter: SubscriptionStatusFilter,
        filter: "includesSome",
      },
    ],
    []
  );

  const onRowClick = (row: Row<SubscriptionTableData>["original"]) => {
    history.push(`${match.url}/${row.id}`);
  };

  return (
    <div className="d-flex flex-column overflow-hidden p-3 min-height-400">
      <div className="mb-3 d-flex justify-content-between">
        <h3>Subscriptions</h3>
      </div>
      <Table
        columns={columns}
        data={subscriptions}
        columnFilterIds={columnFilterIds}
        onRowClick={onRowClick}
        pageCount={pageCount}
        search={search}
        handlePage={setPage}
        currentPage={page}
        searchActionName="SEARCH_SUBSCRIPTIONS"
        sortByForTable={sortByForTable}
      />
    </div>
  );
};

export default SubscriptionsTable;
