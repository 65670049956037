import { Filters } from "./filtersType";
import { Address, Addresses } from "./addressType";
import { OrderHistoryItem, OrderLineItem, Product } from "./orderTypes";
import { Customer } from "./customerTypes";

export enum SubscriptionStatus {
  NEW = 0,
  ACTIVE = 1,
  FAILED = 2,
  CANCELED = 3,
  PAUSED = 4,
  EXPIRED = 5,
}

export const SubscriptionStatusDisplayName = new Map<SubscriptionStatus, string>([
  [SubscriptionStatus.NEW, "New"],
  [SubscriptionStatus.ACTIVE, "Active"],
  [SubscriptionStatus.FAILED, "Failed"],
  [SubscriptionStatus.CANCELED, "Canceled"],
  [SubscriptionStatus.PAUSED, "Pause"],
  [SubscriptionStatus.EXPIRED, "Expired"],
]);

export const SubscriptionStatusKey = new Map<string, number>([
  ["New", SubscriptionStatus.NEW],
  ["Active", SubscriptionStatus.ACTIVE],
  ["Failed", SubscriptionStatus.FAILED],
  ["Canceled", SubscriptionStatus.CANCELED],
  ["Pause", SubscriptionStatus.PAUSED],
  ["Expired", SubscriptionStatus.EXPIRED],
]);

export enum BillingFrequency {
  EVERY_2_WEEKS = "2:week",
  EVERY_3_WEEKS = "3:week",
  MONTHLY = "1:month",
  EVERY_2_MONTHS = "2:month",
  EVERY_3_MONTHS = "3:month",
  EVERY_4_MONTHS = "4:month",
  EVERY_5_MONTHS = "5:month",
  EVERY_6_MONTHS = "6:month",
  EVERY_8_MONTHS = "8:month",
  EVERY_12_MONTHS = "12:month",
  EVERY_22_DAYS = "22:day",
  EVERY_30_DAYS = "30:day",
  EVERY_14_DAYS = "14:day",
}

export const BillingFrequencyDisplayName = new Map<BillingFrequency, string>([
  [BillingFrequency.EVERY_2_WEEKS, "Every 2 Weeks"],
  [BillingFrequency.EVERY_3_WEEKS, "Every 3 Weeks"],
  [BillingFrequency.MONTHLY, "Monthly"],
  [BillingFrequency.EVERY_2_MONTHS, "Every 2 Months"],
  [BillingFrequency.EVERY_3_MONTHS, "Every 3 Months"],
  [BillingFrequency.EVERY_4_MONTHS, "Every 4 Months"],
  // [BillingFrequency.EVERY_5_MONTHS, "Every 5 Months"],
  [BillingFrequency.EVERY_6_MONTHS, "Every 6 Months"],
  // [BillingFrequency.EVERY_8_MONTHS, "Every 8 Months"],
  // [BillingFrequency.EVERY_12_MONTHS, "Every 12 Months"],
  // [BillingFrequency.EVERY_22_DAYS, "Every 22 Days"],
  // [BillingFrequency.EVERY_30_DAYS, "Every 30 Days"],
  // [BillingFrequency.EVERY_14_DAYS, "Every 14 Days"],
]);

export enum CancelReason {
  TOO_EXPENSIVE = 2,
  TOO_MANY_PRODUCTS = 3,
  DOG_PASSED_AWAY = 6,
  OTHERS = 7,
  POOR_CUSTOMER_SERVICE = 11,
  ALLERGIC_REACTION_PALATABILITY = 12,
  DISPUTED_WITH_PAYMENT_GATEWAYS = 14,
  DUPLICATE_SUBSCRIPTION = 16,
  CHANGE_SUPPLEMENT_VET_AGAINST_IT = 21,
  PET_DID_NOT_EAT = 22,
  PREFER_NOT_SAY = 23,
  NO_RESULTS_YET = 26,
  PRODUCT_NO_NEED = 31,
  AMAZON_PURCHASE = 32,
  INCORRECT_ORDER = 33,
  FULFILLMENT_ISSUE = 34,
  PREFER_BUY_AS_NEEDED = 35,
  ANOTHER_PETLAB_PRODUCT = 36,
  ANOTHER_BRAND = 37,
  MEANT_BUY_ONCE = 38,
}

export const SubscriptionCancelReason = new Map<CancelReason, string>([
  [CancelReason.TOO_EXPENSIVE, "Not in my budget right now"],
  [CancelReason.TOO_MANY_PRODUCTS, "I have too many products"],
  [CancelReason.DOG_PASSED_AWAY, "Dog passed away"],
  [CancelReason.OTHERS, "Other"],
  [CancelReason.POOR_CUSTOMER_SERVICE, "I'm not happy with your customer service"],
  [CancelReason.ALLERGIC_REACTION_PALATABILITY, "Adverse Reaction"],
  [CancelReason.DISPUTED_WITH_PAYMENT_GATEWAYS, "Disputed with Payment Gateways"],
  [CancelReason.DUPLICATE_SUBSCRIPTION, "I am removing a duplicate subscription"],
  [CancelReason.CHANGE_SUPPLEMENT_VET_AGAINST_IT, "Vet recommendation"],
  [CancelReason.PET_DID_NOT_EAT, "My pup(s) didn't like the product"],
  [CancelReason.FULFILLMENT_ISSUE, "Fulfillment Issue"],
  [CancelReason.ANOTHER_BRAND, "I am going with another brand"],
  [CancelReason.AMAZON_PURCHASE, "I am purchasing from Amazon instead"],
  [CancelReason.ANOTHER_PETLAB_PRODUCT, "I am switching to a different PetLab product"],
  [CancelReason.NO_RESULTS_YET, "I haven't seen the results yet"],
  [CancelReason.PRODUCT_NO_NEED, "I no longer need the product"],
  [CancelReason.PREFER_BUY_AS_NEEDED, "I prefer to buy as-needed"],
  [CancelReason.INCORRECT_ORDER, "Ordered Incorrectly"],
  [CancelReason.PREFER_NOT_SAY, "Prefer not to say"],
  [CancelReason.MEANT_BUY_ONCE, "I meant to buy one time"],
]);

export interface SubscriptionItem {
  totalPrice: number | undefined;
  id: number | string;
  product_id: number | string;
  sku: any;
  profile?: string;
  product?: string;
  mainProduct?: string;
  main_product?: string | boolean;
  image: string;
  quantity: number;
  primaryProductQuantity?: number;
  price: number;
  item_price: number;
  orderAmount?: number;
  row_total?: number;
  tax_amount: number;
  taxAmount: number;
  tax: number;
  total?: number;
  totalAmount?: number;
  product_name?: string,
  image_url?: string;
  vendor?: boolean;
  productInfo: {};
  items_in_bundle?: number;
  package?: string;
  type?: number;
  typeName?: string;
  status?: number;
  statusName?: string;
  isMainProduct?: boolean;
  new_product?: boolean;
  additional_product_id: number;
}

export interface Subscription extends Addresses {
  data: any;
  id: string;
  schedule?: any,
  amount: string;
  tax: string;
  frequency: string;
  orderId: string;
  signupDate: Date;
  previousBillingDate: Date;
  nextBillingDate: Date;
  next_billing_date: Date;
  billingFrequency: BillingSchedule | BillingFrequency;
  customerId: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  totalPrice: string;
  currency: string;
  status: SubscriptionStatus | Status;
  store: string;
  primaryProductPrice?: string;
  primaryProductId?: string;
  primaryProductStatus?: string;
  primaryProductQuantity?: string;
  taxRate?: string;
  downsell_discount_is_available?: boolean;
  sku: string;
  summary: string;
  image: string;
  billingDate: string;
  startDate: string;
  orderAmount: string;
  statusName: string;
  taxAmount: string;
  totalAmount: string;
  billing?: Billing;
  customer?: Customer;
  shippingAddress: Address;
  billingAddress: Address;
  details: SubscriptionItem[];
  discountAmount?: number;
  additional_products: SubscriptionItem[];
  items?: Product[];
  line_items?: Product[];
  productInfo: any;
  mainProduct: Product[];
  additionalProducts?: Product[];
}

export interface SubscriptionTableData {
  id: number;
  frequency: BillingFrequency;
  product: {
    name: string;
    image: string;
    vendor?: boolean;
  };
  nextBillingDate: Date;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  totalPrice: number;
  status: string;
}

export interface SubscriptionsState {
  subscriptionsList: SubscriptionTableData[];
  subscription: Subscription;
  pageCount: number;
  page: number;
  search: string;
  filters: {
    status: Filters;
    nextBillingDate: Date | null;
  };
  sortBy: [];
  downsellList: Object;
  pendingOrder: OrderLineItem[];
  history: OrderHistoryItem[];
}

export interface ReasonsType {
  reason: string;
  key: number;
}

export interface DifferencesType {
  [key: string]: string | number;
}
interface BillingSchedule {
  schedule_value: number;
  schedule_type: string;
  schedule: string;
}

interface Billing {
  previousBillingDate: Date | null;
  nextBillingDate: Date | null;
  card: {};
}

interface Status {
  status: number;
  statusName: string;
  cancelReason: number;
  cancelMessage: string;
}
