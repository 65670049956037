import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useMappedState } from "redux-react-hook";
import { Column, useTable } from "react-table";
import BaseTable from "react-bootstrap/Table";

import HeadCell from "./Tables/HeadCell";
import { CurrencyType } from "../types/currencyType";
import { OrderStatusDisplayName } from "../types/orderTypes";
import TablePagination from "./Tables/TablePagination";
import { getActualLink } from "../utilities/storeName";

const OneTimePurchases: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();
  const customer = useMappedState((state) => state.customers.customer);
  const { page, shippingAddress } = customer;

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: HeadCell("Shopify"),
        accessor: "shopify_id",
        minWidth: 150,
        Cell: ({ value }) => (
          <a
            href={
              getActualLink(shippingAddress.country, value)
            }
            target="_blank"
            rel="noreferrer"
            className="m-0"
          >
            {value}
          </a>
        ),
      },
      {
        Header: HeadCell("Order"),
        accessor: "api",
        minWidth: 150,
        Cell: ({ row }) => (
          <Link
            to={`/orders/${row.original.id}`}
            rel="noreferrer"
            className="m-0"
          >
            {row.original.id}
          </Link>
        ),
      },
      {
        Header: HeadCell("Date"),
        accessor: "created_at",
        minWidth: 200,
        Cell: ({ value }) => value,
      },
      {
        Header: HeadCell("Price"),
        accessor: "amount",
        minWidth: 100,
        Cell: ({ value }) => `${CurrencyType[value?.currency || "USD"]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Tax"),
        accessor: "tax",
        minWidth: 100,
        Cell: ({ value }) => `${CurrencyType[value?.currency || "USD"]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Total"),
        accessor: "total",
        minWidth: 100,
        Cell: ({ value }) => `${CurrencyType[value?.currency || "USD"]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Status"),
        accessor: "status",
        minWidth: 100,
        Cell: ({ value }) => OrderStatusDisplayName.get(Number(value)),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: history,
  });

  const handlePage = useCallback(
    async (pageNumber: number) => {
      dispatch({
        type: "SET_PAGE_OTP",
        payload: {
          page: pageNumber,
        },
      });
    },
    [dispatch]
  );

  return (
    <div>
      <BaseTable responsive borderless {...getTableProps()} className="history-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className="rounded" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { minWidth } = cell.column;
                  return (
                    <td
                      {...cell.getCellProps({
                        style: { minWidth, width: minWidth },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BaseTable>
      {customer.pagination.page_count > 1 && (
        <TablePagination
          currentPage={page || 1}
          pageCount={customer.pagination.page_count}
          pageIndex={page || 1}
          handlePage={handlePage}
        />
      )}
    </div>
  );
};

export default OneTimePurchases;
