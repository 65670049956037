import { all, put, takeEvery } from "redux-saga/effects";
import token from "../utilities/token";
import user from "../utilities/user";
import { errorWorker } from "../utilities/errors";

function* logoutRequest(): any {
  try {
    yield token.clear();
    user.clear();
    // Redirect to orders page
    window.location.href = "/";
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* loginWatcher() {
  yield takeEvery("LOGOUT", logoutRequest);
}

export default function* rootloginSaga() {
  yield all([loginWatcher()]);
}
