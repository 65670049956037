import React, { useEffect, useState } from "react";
// import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
// import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import CustomerInfoPane from "../components/CustomerInfoPane";
import OrderDetailsTable from "../components/OrderDetailsTable";
import { getDateAtTimeFormatString } from "../utilities/date";
import { ReactComponent as BackIcon } from "../icons/leftArrow.svg";
import {
  Order as IOrder,
  // OrderStatus,
  OrderStatusDisplayName,
  OrderTypeDisplayName,
  PaymentStatusDisplayName,
} from "../types/orderTypes";
import { orderConstants } from "../constants/orderConstants";
// import user from "../utilities/user";
// import { UserRoles } from "../components/Routes";
import NavConfirmationButton from "../components/Buttons/NavConfirmationButton";
import { Routes } from "../constants/routes";

const Order: React.FC = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams<{ orderId: string }>();

  const initialOrders = useMappedState((s): IOrder[] => (s.orders.order.data ? s.orders.order.data : s.orders.order));
  const [orders, setOrders] = useState<IOrder[]>(initialOrders);

  useEffect(() => {
    dispatch({
      type: orderConstants.GET_ORDER,
      payload: orderId,
    });
  }, [orderId, dispatch]);

  useEffect(() => {
    setOrders(initialOrders);
  }, [initialOrders]);

  const renderBody = (order) => {
    const { type, order_date, payment, status, shopifyOrderNotes, shopify_id } = order;
    const items = order?.main_product.concat(order?.additional_products).flat();

    return (
      <>
        <Row>
          <Col>
            <h6 className="m-0">
              <small className="font-weight-bold">Status</small>
            </h6>
            <p>{OrderStatusDisplayName.get(Number(status))}</p>
          </Col>
          <Col>
            <h6 className="m-0">
              <small className="font-weight-bold">Date</small>
            </h6>
            <p>{getDateAtTimeFormatString(order_date)}</p>
          </Col>
          <Col>
            <h6 className="m-0">
              <small className="font-weight-bold">Type</small>
            </h6>
            <p>{OrderTypeDisplayName.get(type)}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className="m-0">
              <small className="font-weight-bold">Payment</small>
            </h6>
            <p>{PaymentStatusDisplayName.get(payment)}</p>
          </Col>
          <Col>
            <h6 className="m-0">
              <small className="font-weight-bold" />
            </h6>
            <p />
          </Col>
        </Row>
        <hr />
        {
          items && <OrderDetailsTable
            order={items}
            shopify_id={shopify_id}
            subscriptionId={order.subscription_id}
            currency={order.currency}
            shop={order.shop_name}
            className="mb-4"
            header={<h4>Order details</h4>}
          />
        }
        <hr />
        {shopifyOrderNotes &&
          <>
            <h4>Order Notes</h4>
            <Form.Control disabled className="border-0 p-0 bg-white" value={shopifyOrderNotes} />
          </>}
      </>
    );
  };

  return (
    <>
      {!orders.length ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <div className="bg-white px-5 py-3 align-items-center justify-content-between">
            <NavConfirmationButton route={`${Routes.CUSTOMERS}/${orders[0].customer.id}`}>
              <BackIcon />
              Return to Customer
            </NavConfirmationButton>
            <div>
              <h4 className="m-0 mr-auto">{orders[0].shopify_order && "Shopify "}
                Order {orders[0].shopify_id || orders[0].api}
              </h4>
            </div>
          </div>
          <Container fluid className="flex-grow-1 overflow-hidden">
            <Row className="h-100" style={{ flexWrap: "nowrap" }}>
              <Col>
                <div className="p-5 h-100 overflow-auto bg-white">
                  {orders.map((order) => (
                    renderBody(order)
                  ))}
                </div>
              </Col>
              <Col className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0" style={{ minWidth: 300 }}>
                {orders[0].customer && <CustomerInfoPane customer={orders[0].customer} address={orders[0]} />}
              </Col>
            </Row>
          </Container>
        </>

      )}
    </>
  );
};

export default Order;
