export interface Address {
  [key: string]: string | null;
  id: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  street: string;
  street_2: string;
  street3: string;
  city: string;
  country: string;
  zip: string;
  stateProvince: string;
}

export interface Addresses {
  billingAddress: Address;
  shippingAddress: Address;
  billing_address?: Address;
  shipping_address?: Address;
}

export const AddressState: Address = {
  id: null,
  city: "",
  country: "",
  firstName: "",
  lastName: "",
  phone: "",
  stateProvince: "",
  street: "",
  street_2: "",
  street3: "",
  zip: "",
};
