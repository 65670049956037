import React, { useMemo } from "react";
import { Column, useTable } from "react-table";
import BaseTable from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import HeadCell from "./Tables/HeadCell";
import { CurrencyType } from "../types/currencyType";
import { getActualLink } from "../utilities/storeName";
import { OrderHistoryItem, OrderHistoryProps } from "../types/orderHistoryType";
import { Routes } from "../constants/routes";

const OrderHistory: React.FC<OrderHistoryProps> = ({
  history,
  currency,
  showAllHistory,
  toggleShowAllHistory,
  ...forwardProps
}) => {
  const columns: Column<OrderHistoryItem>[] = useMemo(
    () => [
      {
        Header: HeadCell("Shopify"),
        accessor: "shopify_order_id",
        minWidth: 150,
        Cell: ({ row, value }) => (
          <a
            href={
              getActualLink(row.original.shop_name, row.original.shopify_order_id)
            }
            target="_blank"
            rel="noreferrer"
            className="m-0"
          >
            {value}
          </a>
        ),
      },
      {
        Header: HeadCell("Order"),
        accessor: history[0].shopify_id ? "shopify_id" : "shopify_order_id",
        minWidth: 150,
        Cell: ({ row }) => (
          <a
            href={
              row.original.shopify_id
                ? `${Routes.ORDERS}/${row.original.shopify_id}`
                : getActualLink(row.original.shop_name, row.original.shopify_order_id)
            }
            rel="noreferrer"
            className="m-0"
          >
            {row.original.shopify_id ? row.original.shopify_id : row.original.shopify_order_id}
          </a>
        ),
      },
      {
        Header: HeadCell("Date"),
        accessor: "created_at",
        minWidth: 200,
      },
      {
        Header: HeadCell("Qty"),
        accessor: "quantity",
        minWidth: 70,
      },
      {
        Header: HeadCell("Price"),
        accessor: "price",
        minWidth: 100,
        Cell: ({ value }) => `${CurrencyType[currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Tax"),
        accessor: "tax",
        minWidth: 100,
        Cell: ({ value }) => `${CurrencyType[currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Total"),
        accessor: "total",
        minWidth: 100,
        Cell: ({ value }) => `${CurrencyType[currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Status"),
        accessor: "status",
        minWidth: 100,
      },
    ],
    [currency]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: history,
  });

  return (
    <div {...forwardProps}>
      <div className="d-flex align-items-center justify-content-between">
        <h4>Order History</h4>
        <Button variant="link" className="font-weight-bold font-size-sm" onClick={toggleShowAllHistory}>
          {showAllHistory ? "Collapse" : "Show all"}
        </Button>
      </div>
      <BaseTable responsive borderless {...getTableProps()} className="history-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr className="rounded" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { minWidth } = cell.column;

                  return (
                    <td
                      {...cell.getCellProps({
                        style: { minWidth, width: minWidth },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BaseTable>
    </div>
  );
};

export default OrderHistory;
