function toCamelCase(str) {
  return str.replace(/([-_][a-z])/g, (group) => (group.toUpperCase().replace("-", "").replace("_", "")));
}

export default function keysToCamel(obj) {
  if (Array.isArray(obj)) {
    return obj.map((val) => keysToCamel(val));
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((result, [key, val]) => {
      result[toCamelCase(key)] = keysToCamel(val);
      return result;
    }, {});
  }
  return obj;
}
