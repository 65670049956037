import { errorConstants } from "../constants/errorConstants";

export const errorWorker = (error: any) => {
  console.log("error", error);
  const errorStatus = error.response?.status;
  const errorMessage = error?.response?.data.data.error;
  const errorDetail = error?.response?.data.detail;

  return {
    type: errorConstants.SET_ERROR,
    payload: {
      errorStatus: errorStatus || 500,
      errorTitle: error.response?.data.title || "",
      errorDetail: errorDetail || "",
      errorType: error.response?.data.type || "",
      errorMessage: errorMessage || "",
    },
  };
};
