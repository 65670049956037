import React from "react";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import Nav from "react-bootstrap/Nav";
import { SuccessState as ISuccess } from "../../types/successTypes";

const SuccessModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    successStatus,
    successMessage,
    successTitle,
    successDetail,
  } = useMappedState((state): ISuccess => state.success);

  const subscription = useMappedState((state): any => state.subscriptions.subscription);
  const onCloseModal = () => {
    dispatch({
      type: "CLEAN_SUCCESS",
    });
  };

  return (
    <BaseModal
      show={!!successStatus}
      centered
    >
      <BaseModal.Header className="px-4">
        <BaseModal.Title>Subscription #{subscription.id}</BaseModal.Title>
        <button type="button" className="close" onClick={onCloseModal}>
          ×
        </button>
      </BaseModal.Header>
      <BaseModal.Body className="p-0">
        <Container className="pt-5 pb-4 px-4">
          {successMessage && <p>Success! {successMessage}.</p>}
          {successTitle && <h5 className="m-0 small font-weight-bold">{successTitle}</h5>}
          {successDetail && <p>{successDetail}</p>}
          <Nav className="mt-4 justify-content-end">
            <Button
              variant="outline-primary"
              className="font-weight-bold"
              style={{ width: 120, height: 40 }}
              onClick={onCloseModal}
            >
              Close
            </Button>
          </Nav>
        </Container>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default SuccessModal;
