import React, { useMemo } from "react";
import { CellProps, Column } from "react-table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import DetailsTable from "./DetailsTable";
import EditableCell from "./TableCells/EditableCell";
import ProductCell from "./TableCells/ProductCell";
import CheckboxCell from "./TableCells/CheckboxCell";
import { Order, Product } from "../types/orderTypes";
import transformProductItems from "../utilities/product";
import NumberEditableCell from "./TableCells/NumberEditableCell";
import { CurrencyType } from "../types/currencyType";
import { getActualLink, getStoreName } from "../utilities/storeName";
import { Routes } from "../constants/routes";
import HeadCell from "./Tables/HeadCell";

export interface OrderItem {
  id: number;
  sku: any;
  product: Product;
  productInfo: {};
  image: string;
  quantity: number;
  price: number;
  tax: number;
  total: number;
  subscription: number;
}

export interface OrderDetailsTableProps {
  order: Order;
  isEditing?: boolean;
  isSaving?: boolean;
  selectAllRows?: boolean | null;
  setSelectedItems?: (items: Product[]) => void;
  setIsAllRowsSelected?: (isAllRowsSelected: boolean) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  currency?: string;
  shop?: string;
  isDownsell?: boolean;
  subscriptionId?: number;
  shopify_id?: string;
}

const OrderDetailsTable: React.FC<OrderDetailsTableProps> = ({
  order,
  isEditing = false,
  isSaving = false,
  selectAllRows = false,
  setSelectedItems,
  setIsAllRowsSelected,
  header,
  footer,
  currency = "USD",
  shop,
  isDownsell = true,
  subscriptionId,
  shopify_id,
  ...forwardProps
}) => {
  const data = useMemo(() => {
    const itemsToTransform = Array.isArray(order) ? order : (order.items || [order]);
    return transformProductItems(itemsToTransform);
  }, [order]);

  const itemPrice = data.length && data.reduce((a, b) => +a + +b.price, 0);
  const tax = data.length && data.reduce((a, b) => +a + +b.tax, 0);
  const total = data.length && data.reduce((a, b) => +a + +b.totalAmount, 0);
  const arrSubscriptions = data.filter((item: OrderItem) => item.subscription);
  const isHasSubscriptions = arrSubscriptions.length >= 1;

  const columns: Column<Product>[] = useMemo(
    () => [
      {
        Header: HeadCell("No."),
        id: "index",
        Cell: ({ row }: CellProps<OrderItem>) => row.index + 1,
      },
      ...subscriptionId ? [{
        Header: HeadCell("Sub ID."),
        id: "subscription_id",
        Cell: () => (
          <Link
            to={`/subscriptions/${subscriptionId}`}
            rel="noreferrer"
            className="m-0"
          >
            {subscriptionId}
          </Link>
        ),
      }] : [],
      {
        Header: HeadCell("SKU"),
        accessor: "sku",
      },
      {
        Header: HeadCell("Product"),
        accessor: "productInfo",
        Cell: ProductCell,
      },
      {
        Header: HeadCell("Qty"),
        accessor: "quantity",
        ...(isEditing && !isSaving && { Cell: NumberEditableCell }),
      },
      {
        Header: HeadCell("Price"),
        accessor: "price",
        ...(isEditing && !isSaving
          ? { Cell: EditableCell }
          : {
            Cell: ({ value }) => `${CurrencyType[currency]}${Number(value).toFixed(2)}`,
          }),
      },
      {
        Header: HeadCell("Tax"),
        accessor: "tax",
        Cell: ({ value }) => `${CurrencyType[currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Total"),
        accessor: data[0].row_total ? "row_total" : "total",
        Cell: ({ value }) => `${CurrencyType[currency]}${Number(value).toFixed(2)}`,
      },
      ...(setSelectedItems ? [{ id: "selection", minWidth: 10, Cell: CheckboxCell }] : []),
    ],
    [isEditing, isSaving, setSelectedItems, order]
  );

  function addSubscriptionCol(): void {
    if (isHasSubscriptions) {
      columns.splice(1, 0,
        {
          Header: HeadCell("Sub ID"),
          accessor: "subscription",
          Cell: ({ value }: CellProps<OrderItem>) => (
            <Link to={`${Routes.SUBSCRIPTIONS}/${value}`}>{value}</Link> || "-"),
        });
    }
  }

  addSubscriptionCol();
  return (
    <div {...forwardProps}>
      <Row className="mb-3 justify-content-center align-items-center">
        <Col className="d-flex align-items-center">{header}</Col>
        <Col
          sm="auto"
          className="d-flex flex-column flex-xl-row align-items-center justify-content-end"
        >
          {!isDownsell ? (
            <Badge variant="success" className="mt-3 mt-lg-0 mr-0 mr-xl-3 mb-2 mb-xl-0">
              Downsell has been applied to this order
            </Badge>
          ) : null}
          {shop &&
            <>
              <h6 className="small font-weight-bold m-0 mr-0 mr-xl-2">Shop</h6>
              <p className="small m-0">{getStoreName(shop)}</p>
            </>}
        </Col>
        {shopify_id &&
          <Col sm={4} className="d-flex align-items-center justify-content-end">
            <h6 className="small font-weight-bold m-0 mr-3">Shopify Order</h6>
            <a
              href={
                getActualLink(shop, shopify_id)
              }
              target="_blank"
              rel="noreferrer"
              className="small m-0"
            >
              {shopify_id}
            </a>
          </Col>}
      </Row>
      <Row>
        <Col>
          <DetailsTable
            columns={columns}
            data={data}
            isEditing={isEditing}
            isSaving={isSaving}
            selectAllRows={selectAllRows}
            setSelectedRows={setSelectedItems}
            setIsAllRowsSelected={setIsAllRowsSelected}
          />
        </Col>
      </Row>
      <Row className="flex-wrap-reverse">
        <Col md={6} xl={8}>
          {footer}
        </Col>
        <Col md={2} xl={1} className="text-right">
          <h6 className="m-0 small font-weight-bold">Total Item Price</h6>
          <p>
            {CurrencyType[currency]}
            {Number(itemPrice).toFixed(2)}
          </p>
        </Col>
        <Col md={2} xl={1} className="text-right">
          <h6 className="m-0 small font-weight-bold">Total Tax</h6>
          <p>
            {CurrencyType[currency]}
            {Number(tax).toFixed(2)}
          </p>
        </Col>
        <Col md={2} xl={1} className="text-right">
          <h6 className="m-0 small font-weight-bold">Shipping</h6>
          <p>
            {CurrencyType[currency]}
            {Number(order.shipping || 0).toFixed(2)}
          </p>
        </Col>
        <Col md={2} xl={1} className="text-right">
          <h6 className="m-0 small font-weight-bold">Total Amount</h6>
          <p>
            {CurrencyType[currency]}
            {Number(total).toFixed(2)}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetailsTable;
