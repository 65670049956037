import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useMappedState } from "redux-react-hook";
import { Subscription } from "../../types/subscriptionTypes";
import CancelModal from "./CancelModal";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  subscriptionId: Subscription;
  setCancelReason: (reason: React.SetStateAction<number>) => void;
  setCancelMessage: (message: React.SetStateAction<string>) => void;
  cancelSubscription: (id: string | number) => void;
  cancelReason: number;
  buttonText?: string;
};

const CancelSubscriptionModal: React.FC<Props> = ({
  subscriptionId,
  setCancelReason,
  setCancelMessage,
  cancelSubscription,
  cancelReason,
  buttonText,
}) => {
  const [show, setShow] = React.useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <>
      <Button
        variant="outline-primary"
        className={`${buttonText ? "p-0" : ""} font-size-sm font-weight-semibold`}
        style={{ minWidth: 104, height: 40 }}
        onClick={onShow}
      >
        {buttonText || "Cancel"}
      </Button>
      <CancelModal
        show={show}
        onHide={onHide}
        subscriptionId={subscriptionId}
        setCancelReason={setCancelReason}
        setCancelMessage={setCancelMessage}
        cancelSubscription={cancelSubscription}
        cancelReason={cancelReason}
      />
    </>
  );
};

CancelSubscriptionModal.defaultProps = {
  buttonText: "",
};

export default CancelSubscriptionModal;
