import { all, put, takeEvery } from "redux-saga/effects";
import { orderConstants } from "../constants/orderConstants";
import orderService from "../services/orderService";
import { errorWorker } from "../utilities/errors";

function* getOrder(id: any) {
  try {
    yield put({
      type: orderConstants.SET_ORDER_LOADING,
      payload: {
        order: {},
        status: "success",
      },
    });
    const { data }: any = yield orderService.getOrder(id.payload);
    yield put({
      type: orderConstants.SET_ORDER,
      payload: {
        order: data.data.shopify_order_list,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* orderWatcher() {
  yield takeEvery(orderConstants.GET_ORDER, getOrder);
}

export default function* rootorderSaga() {
  yield all([orderWatcher()]);
}
