import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useDispatch } from "redux-react-hook";
import { Button } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";
import { matchPath, useLocation } from "react-router-dom";

export interface ColumnFilters {
  [id: string]: React.ReactNode;
}

export interface SearchAndFilterProps {
  searchPlaceholder: string;
  setGlobalFilter: (searchTerm: string) => void;
  columnFilters: ColumnFilters;
  columnFilterIds?: string[];
  className?: string;
  searchActionName?: string;
  search: string;
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchPlaceholder,
  setGlobalFilter,
  columnFilters,
  columnFilterIds,
  searchActionName,
  search,
  ...forwardProps
}) => {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(search);

  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [firstName, setFirstName] = useState(searchParams.get("firstname") || "");
  const [lastName, setLastName] = useState(searchParams.get("lastname") || "");
  const [phoneNumber, setPhoneNumber] = useState(searchParams.get("phone") || "");
  const [phoneError, setPhoneError] = useState("");
  const [currentSearch, setCurrentSearch] = useState(search);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if ((/^\d*$/.test(value) && !/\s/.test(value)) || value === "") {
      setPhoneNumber(value.trim());
      setPhoneError("");
    } else {
      setPhoneError("Please enter a number.");
    }
  };

  const onSearchChange = useAsyncDebounce((searchTerm: string) => {
    dispatch({
      type: searchActionName,
      payload: { search: searchTerm },
    });
  }, 1000);

  const handleSearch = () => {
    const query = [
      email && `email=${email}`,
      firstName && `firstname=${firstName}`,
      lastName && `lastname=${lastName}`,
      phoneNumber && `phone=${phoneNumber}`,
    ]
      .filter(Boolean)
      .join("&");

    setPhoneError("");
    dispatch({
      type: searchActionName,
      payload: { search: query },
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const location = useLocation();

  const isCustomersPage = matchPath(location.pathname, {
    path: "/customers",
    exact: true,
  });

  return (
    <InputGroup {...forwardProps}>
      {
        !isCustomersPage ?
          <>
            <Form.Control
              placeholder={searchPlaceholder}
              value={currentSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentSearch(e.target.value);
                if (e.target.value.length > 2 || e.target.value.length === 0) {
                  onSearchChange(e.target.value);
                }
              }}
            />
            <InputGroup.Append className="bg-white">
              {columnFilterIds &&
                columnFilterIds.map((id) => <React.Fragment key={id}>{columnFilters[id]}</React.Fragment>)}
            </InputGroup.Append>
          </>
          : (
            <>
              <InputGroup.Prepend>
                <InputGroup.Text className="bg-white">&#x2315;</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onKeyPress={handleKeyPress}
                maxLength={100}
              />
              <Form.Control
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value.trim())}
                onKeyPress={handleKeyPress}
                maxLength={50}
              />
              <Form.Control
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value.trim())}
                onKeyPress={handleKeyPress}
                maxLength={50}
              />
              <div>
                <Form.Control
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  onKeyPress={handleKeyPress}
                  maxLength={15}
                />
                {phoneError && <div className="text-danger error">{phoneError}</div>}
              </div>
              <Button
                onClick={handleSearch}
                disabled={!email && !firstName && !lastName && !phoneNumber}
              >
                Search
              </Button>
            </>
          )
      }
    </InputGroup>
  );
};

export default SearchAndFilter;
