import { all, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import customerService from "../services/customerService";
import { customerConstants } from "../constants/customerConstants";
import { errorWorker } from "../utilities/errors";
import {
  clearLoading, clearLoadingActive, clearLoadingCancelled,
  clearLoadingExpired, clearLoadingFailed,
  clearLoadingPaused, setLoading, setLoadingActive,
  setLoadingCancelled, setLoadingExpired,
  setLoadingFailed, setLoadingPaused,
  setLoadingOTP, clearLoadingOTP,
} from "../utilities/loading";
import keysToCamel from "../utilities/camelCase";

function* getCustomers(action: any) {
  try {
    yield put(setLoading());
    if (action.payload.search) {
      const searchData = action.payload.search.split("&");
      searchData.forEach((pair) => {
        const [key, value] = pair.split("=");

        if (key && value) {
          action.payload[key] = value;
        }
      });

      delete action.payload.search;
    }
    const data: any = yield customerService.getAll(action.payload);
    yield put(clearLoading());

    if (!data.data) return;
    yield put({
      type: customerConstants.SET_ALL_CUSTOMERS,
      payload: {
        customers: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(clearLoading());
    yield put(errorWorker(error));
  }
}

function* getCustomer(id: any) {
  try {
    const { data }: AxiosResponse = yield customerService.getCustomer(id.payload);
    const userData = keysToCamel(data.data);
    const [firstName = undefined, lastName = undefined] = userData.name ? userData.name.split(" ") : [];

    const normalizeAddress = (address) => (
      address || {
        id: "",
        firstName,
        lastName,
        phone: userData.phone || "",
        street: "",
        street_2: "",
        city: "",
        country: "US",
        zip: "",
        stateProvince: "",
      }
    );
    const normalizedBillingAddress = normalizeAddress(userData.billingAddress);
    const normalizedShippingAddress = normalizeAddress(userData.shippingAddress);

    yield put({
      type: customerConstants.SET_CUSTOMER,
      payload: {
        customer: {
          ...userData,
          billingAddress: normalizedBillingAddress,
          shippingAddress: normalizedShippingAddress,
        },
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* updateCustomer(props: any): any {
  try {
    const customer: any = props.payload.updatedCustomer;

    yield put({
      type: customerConstants.SET_CUSTOMER,
      payload: {
        customer,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* sendOnUpdateCustomer(props: any): any {
  try {
    yield put(setLoading());
    yield customerService.updateCustomer(props.payload);
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoading());
  }
}

function* getActiveSubscriptions(action: any) {
  try {
    yield put(setLoadingActive());
    const { data }: AxiosResponse = yield customerService.getActiveSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_ACTIVE_SUBSCRIPTIONS,
      payload: {
        activeSubscriptions: data.data.subscriptions,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingActive());
  }
}

function* getCancelledSubscriptions(action: any) {
  try {
    yield put(setLoadingCancelled());
    const { data }: AxiosResponse = yield customerService.getCancelledSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_CANCELLED_SUBSCRIPTIONS,
      payload: {
        cancelledSubscriptions: data.data.subscriptions,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingCancelled());
  }
}

function* getExpiredSubscriptions(action: any) {
  try {
    yield put(setLoadingExpired());
    const { data }: AxiosResponse = yield customerService.getExpiredSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_EXPIRED_SUBSCRIPTIONS,
      payload: {
        expiredSubscriptions: data.data.subscriptions,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingExpired());
  }
}

function* getFailedSubscriptions(action: any) {
  try {
    yield put(setLoadingFailed());
    const { data }: AxiosResponse = yield customerService.getFailedSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_FAILED_SUBSCRIPTIONS,
      payload: {
        failedSubscriptions: data.data.subscriptions,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingFailed());
  }
}

function* getPausedSubscriptions(action: any) {
  try {
    yield put(setLoadingPaused());
    const { data }: AxiosResponse = yield customerService.getPausedSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_PAUSED_SUBSCRIPTIONS,
      payload: {
        pausedSubscriptions: data.data.subscriptions,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingPaused());
  }
}

function* getOneTimePurchases(props: any): any {
  try {
    yield put(setLoadingOTP());
    const { data } = yield customerService.getOneTimePurchases(props.payload);
    yield put({
      type: customerConstants.SET_ONE_TIME_PURCHASES,
      payload: {
        purchases: data.data.shopify_orders,
        pagination: data.data.pagination_metadata,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  } finally {
    yield put(clearLoadingOTP());
  }
}

function* customerWatcher() {
  yield takeEvery(customerConstants.GET_ALL_CUSTOMERS, getCustomers);
  yield takeEvery(customerConstants.GET_CUSTOMER, getCustomer);
  yield takeEvery(customerConstants.UPDATE_CUSTOMER, updateCustomer);
  yield takeEvery(customerConstants.SEND_UPDATE_DATA, sendOnUpdateCustomer);
  yield takeEvery(customerConstants.GET_ACTIVE_SUBSCRIPTIONS, getActiveSubscriptions);
  yield takeEvery(customerConstants.GET_CANCELLED_SUBSCRIPTIONS, getCancelledSubscriptions);
  yield takeEvery(customerConstants.GET_FAILED_SUBSCRIPTIONS, getFailedSubscriptions);
  yield takeEvery(customerConstants.GET_EXPIRED_SUBSCRIPTIONS, getExpiredSubscriptions);
  yield takeEvery(customerConstants.GET_PAUSED_SUBSCRIPTIONS, getPausedSubscriptions);
  yield takeEvery(customerConstants.GET_ONE_TIME_PURCHASES, getOneTimePurchases);
}

export default function* rootcustomerSaga() {
  yield all([customerWatcher()]);
}
