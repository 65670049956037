import { combineReducers } from "redux";
import customers from "./customers";
import orders from "./orders";
import products from "./products";
import subscriptions from "./subscriptions";
import errors from "./errors";
import success from "./success";
import loading from "./loading";

export default combineReducers({
  customers,
  orders,
  products,
  subscriptions,
  errors,
  success,
  loading,
});
