import { Auth } from "aws-amplify";
import axios, { AxiosRequestConfig } from "axios";
import { Routes } from "../constants/routes";
import token from "./token";

const authErrorMessage = "The email address or password is incorrect. Please try again.";

const API_BASE_URL = process.env.REACT_APP_API_BASE_NEW_URL;

const axiosAPI = axios.create();
axiosAPI.interceptors.request.use((config: AxiosRequestConfig) => {
  config.baseURL = API_BASE_URL;
  config.headers = {
    Authorization: `Bearer ${token.get()}`,
  };

  if (process.env.NODE_ENV !== "production") {
    config.params = {
      ...config.params,
      XDEBUG_SESSION_START: "PHPSTORM",
    };
  }
  return config;
});

axiosAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = token.getRefreshToken();

    if (refreshToken && !error.response) {
      try {
        const data = await Auth.currentSession();
        const accessToken = data.getAccessToken().getJwtToken();

        token.set(accessToken);
        token.setRefreshToken(refreshToken);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } catch (err) {
        console.log(err);
        token.clear();
        window.location.href = Routes.LOGIN;
      }
    } else if (
      error.response.status === 401
      && error.response.data.message === authErrorMessage
    ) {
      token.clear();
      window.location.href = Routes.LOGIN;
    }
    return Promise.reject(error);
  }
);

export default axiosAPI;
