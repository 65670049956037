const validateAddressFields = (address) => {
  const addressKeys = ["firstName", "lastName", "street", "zip", "city", "stateProvince"];
  const inputErrors = {};

  addressKeys.forEach((key) => {
    if (address[key].trim() === "") {
      const capitalizedName = key.charAt(0).toUpperCase() + key.slice(1);

      inputErrors[key] = `${capitalizedName} cannot be empty`;
    }
  });

  return inputErrors;
};

const validateEmail = (email) => {
  const emailRegex = RegExp(/^[^@]+@[^@]+\.[^@]+$/, "i");
  const isValidEmail = String(email)
    .toLowerCase()
    .match(emailRegex);

  return isValidEmail;
};

export const validateFields = (fields) => {
  const addressKeys = ["billingAddress", "shippingAddress"];
  const fieldNames = ["name", "email", "phone", "shopifyId"];
  const inputErrors = {};

  addressKeys.forEach((addressKey) => {
    if (addressKey in fields) {
      const addressErrors = validateAddressFields(fields[addressKey]);
      if (Object.keys(addressErrors).length > 0) {
        inputErrors[addressKey] = addressErrors;
      }
    }
  });

  fieldNames.forEach((name) => {
    if (name in fields && fields[name].trim() === "") {
      const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
      inputErrors[name] = `${capitalizedName} cannot be empty`;
    }
  });

  if (fields.email && !validateEmail(fields.email)) {
    const emailName = "email";
    inputErrors[emailName] = "Please enter a valid email address";
  }

  return inputErrors;
};
