import axiosAPI from "../utilities/axios";

export default {
  getSubscription: (id: any) => axiosAPI.get(`/subscriptions/${id}`),
  getSubscriptionOrders: (id: any) => axiosAPI.get(`/subscriptions/${id}/orders`),
  updateSubscription: async (props: any) => await axiosAPI.put(`/subscriptions/${props.id}`, props.data),
  downsellSubscription: async (props: any) => (
    await axiosAPI.post(`/subscriptions/${props.id}/downsell-discount`, props.body)
  ),
  cancelSubscription: async (props: any) => await axiosAPI.post(`/subscriptions/${props.id}/cancel`, props.data),
  restartSubscription: async (props: any) => await axiosAPI.post(`/subscriptions/${props.id}/reactivate`, props.data),
  addSubscriptionAdditionalProducts: async (props: any) => (
    await axiosAPI.post(`/subscriptions/${props.subscriptionId}/additional-products`, props.data)
  ),
  updateSubscriptionAdditionalProducts: async (props: any) => (
    await axiosAPI.put(`/subscriptions/${props.subscriptionId}/additional-products/${props.additionalProductId}`,
      props.data)
  ),
  deleteSubscriptionAdditionalProducts: async (props: any) => (
    await axiosAPI.delete(`/subscriptions/${props.subscriptionId}/additional-products/${props.additionalProductId}`)
  ),
};
