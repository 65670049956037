import React, { useCallback, useState, useMemo } from "react";
import { Link, matchPath, generatePath, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import { nameMappings, RouteTitles } from "../constants/routes";
import token from "../utilities/token";
import { isLoggedUser } from "../utilities/loggedUser";

const NavigationBar: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const subscription = useMappedState((state) => state.subscriptions.subscription);
  const order = useMappedState((state) => state.orders.order);
  const [customerId, setCustomerId] = useState("");
  const pathnameArray = pathname.split("/").filter((e) => e);

  useMemo(() => {
    if (pathname.includes("customers")) {
      setCustomerId(pathnameArray[1]);
    } else if (subscription.customer) {
      setCustomerId(subscription.customer.id);
    } else if (order.length && order[0].customer) {
      setCustomerId(order[0].customer.id);
    }
  }, [pathname, customerId, order.length, subscription?.customer?.id]);

  const getBreadcrumbs = () => {
    const allowedPaths = Object.keys(nameMappings);

    const breadcrumbsArr = allowedPaths.reduce((breadcrumbs, path) => {
      const matched = matchPath(pathname, path);

      if (matched?.isExact) {
        return nameMappings[path].map((a: string, i: number) => {
          // if it's a base page then display static path
          if (!i) return { name: RouteTitles[a], url: a };

          if (!customerId) return;

          // if customer page or Breadcrumbs generated on top of customer page then add customer id on position 1
          if (i === 1) return { name: RouteTitles[a], url: generatePath(a, { customerId }) };

          // the rest is for the 3-length breadcrumbs having their ID used from the matched params from the path
          return { name: RouteTitles[a], url: generatePath(a, { ...matched.params }) };
        });
      }

      return breadcrumbs;
    }, []);

    return breadcrumbsArr;
  };

  const logOut = useCallback(() => {
    dispatch({
      type: "LOGOUT",
    });
  }, [dispatch]);

  const breadcrumbsArray = getBreadcrumbs();

  const isBreadcrumbVisible = () => ((breadcrumbsArray?.length === 1) || (breadcrumbsArray?.length > 1 && customerId));

  return (
    <Navbar bg="white">
      <Breadcrumb className="flex-grow-1" listProps={{ className: "m-0 p-0 bg-transparent" }}>
        {isBreadcrumbVisible() &&
          breadcrumbsArray.map(({ name, url }) => (
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: url }}
              key={url}
            >
              {name}
            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
      {
        isLoggedUser(token) && (
          <Button variant="primary" onClick={logOut}>
            Logout
          </Button>
        )
      }
    </Navbar>
  );
};

export default NavigationBar;
