import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import SubscriptionDetailsTable from "./SubscriptionDetailsTable";
import { Routes } from "../constants/routes";
import { Subscription } from "../types/subscriptionTypes";

type OtherSubscriptionsListProps = {
  subscriptions: Subscription[];
};

const OtherTypesSubscriptionsList: React.FC<OtherSubscriptionsListProps> = ({
  subscriptions,
}) => (
  <>
    {subscriptions.map((subscription) => (
      <React.Fragment key={subscription.id}>
        <hr />
        <Row>
          <Col sm={6}>
            <Link to={`${Routes.SUBSCRIPTIONS}/${subscription.id}`} className="h4 d-inline m-0">
              {`Subscription ${subscription.id}`}
            </Link>
            <p className="d-inline ml-3 my-0">{subscription.schedule.schedule}</p>
          </Col>
          <Col className="d-flex align-items-center">
            <h6 className="small font-weight-bold m-0 mr-3">Next Billing Date</h6>
            <p className="small m-0">{subscription.next_billing_date || "N/A"}</p>
          </Col>
        </Row>
        <SubscriptionDetailsTable
          initialSubscription={subscription}
          isEditing={false}
          setIsEditing={() => {
          }}
        />
      </React.Fragment>
    ))}
  </>
);

export default OtherTypesSubscriptionsList;
