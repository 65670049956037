import React from "react";
import Button from "react-bootstrap/Button";
import { Auth } from "aws-amplify";

const Login: React.FC = () => {
  const signIn = async () => {
    try {
      Auth.federatedSignIn({ customProvider: "AzureAD" });
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  return (
    <Button variant="primary" type="submit" onClick={signIn}>
      Sign In
    </Button>
  );
};

export default Login;
