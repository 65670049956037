import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import token from "../utilities/token";
import user from "../utilities/user";

const OAuthCallback: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          Auth.currentSession()
            .then((res) => {
              const accessToken = res.getAccessToken();
              const refreshToken = res.getRefreshToken().getToken();
              const jwt = accessToken.getJwtToken();
              user.set(["Agent"]);

              token.set(jwt);
              token.setRefreshToken(refreshToken);

              history.push("/login");
            })
            .catch((err) => {
              console.error("Error while getting current session:", err);
            });

          break;
        case "signOut":
          token.clear();
          user.clear();
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <div>Welcome...</div>
  );
};

export default OAuthCallback;
