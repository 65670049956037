import axiosAPI from "../utilities/axios";

export default {
  getProducts: (props: any) => axiosAPI.get("/products",
    {
      params: {
        ...props,
        page_number: 1,
        per_page: 10,
      },
    }),
};
