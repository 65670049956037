import React from "react";
import Form from "react-bootstrap/Form";
import AddressesForm from "./AddressesForm";
import Activity from "./Activity";
import CustomerInfoPaneForm from "./CustomerInfoPaneForm";
import { Customer } from "../types/customerTypes";
import { Addresses } from "../types/addressType";
import { subscriptionConstants } from "../constants/subscriptionConstants";

export interface CustomerInfoPaneProps {
  customer: Customer;
  address: Addresses;
  isEditing?: boolean;
  isSaving?: boolean;
  className?: string;
}

const keys = ["street", "street_2", "city", "stateProvince", "zip", "country"];

const CustomerInfoPane: React.FC<CustomerInfoPaneProps> = ({
  customer,
  address,
  isEditing = false,
  isSaving = false,
}) => (
  <>
    {!isEditing ? (
      <>
        <CustomerInfoPaneForm customer={customer} />
        <Form.Group controlId="shippingAddress">
          <Form.Label className="small font-weight-bold">Shipping Address</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={2}
            style={{ resize: "none" }}
            disabled={!isEditing || isSaving}
            value={keys.map((key: string) => (address.shipping_address
              ? address.shipping_address![key] : address.shippingAddress![key])).join(" ")}
            className={`bg-gray-light ${isEditing && !isSaving ? "border border-secondary " : "border-0 p-0 "}`}
          />
        </Form.Group>
        <Form.Group controlId="billingAddress">
          <Form.Label className="small font-weight-bold">Billing Address</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={2}
            style={{ resize: "none" }}
            disabled={!isEditing || isSaving}
            value={keys.map((key: string) => (address.billing_address
              ? address.billing_address![key] : address.billingAddress![key])).join(" ")}
            className={`bg-gray-light ${isEditing && !isSaving ? "border border-secondary " : "border-0 p-0 "}`}
          />
        </Form.Group>
        <hr />
        <Activity customer={customer} isSaving={isSaving} />
      </>
    ) : (
      <AddressesForm
        addresses={address}
        action={subscriptionConstants.UPDATE_SUBSCRIPTION_ADDRESS}
        isEditing={isEditing}
        isSaving={isSaving}
      />
    )}
  </>
);

export default CustomerInfoPane;
