import React, { SetStateAction } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import SubscriptionDetailsTable from "./SubscriptionDetailsTable";
import { Routes } from "../constants/routes";
import CancelSubscriptionModal from "./Modals/CancelSubscriptionModal";
import { getDateFormat } from "../utilities/date";
import { Subscription } from "../types/subscriptionTypes";

export interface SubscriptionsListProps {
  subscriptionIds: Subscription[];
  setCancelReason: (reason: SetStateAction<number>) => void;
  setCancelMessage: (message: SetStateAction<string>) => void;
  cancelSubscription: (id: string | number) => void;
  cancelReason: number;
}

const SubscriptionsList: React.FC<SubscriptionsListProps> = ({
  subscriptionIds,
  setCancelReason,
  setCancelMessage,
  cancelSubscription,
  cancelReason,
}) => (
  <>
    {
      subscriptionIds.map((subscriptionId) => (
        <React.Fragment key={subscriptionId.id}>
          <hr />
          <Row>
            <Col sm={6}>
              <Link to={`${Routes.SUBSCRIPTIONS}/${subscriptionId.id}`} className="h4 d-inline m-0">
                {`Subscription ${subscriptionId.id}`}
              </Link>
              <p className="d-inline ml-3 my-0">{subscriptionId.schedule.schedule}</p>
            </Col>
            <Col className="d-flex align-items-center">
              <h6 className="small font-weight-bold m-0 mr-3">Next Billing Date</h6>
              <p className="small m-0">{getDateFormat(subscriptionId.schedule.next_billing_date)}</p>
            </Col>
            <Col className="d-flex align-items-center">
              <h6 className="small font-weight-bold m-0 mr-3">Start Date</h6>
              <p className="small m-0">
                {getDateFormat(subscriptionId.schedule.previous_billing_date)}
              </p>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              <CancelSubscriptionModal
                subscriptionId={subscriptionId}
                setCancelReason={setCancelReason}
                setCancelMessage={setCancelMessage}
                cancelSubscription={cancelSubscription}
                cancelReason={cancelReason}
                buttonText="Cancel subscription"
              />
            </Col>
          </Row>
          <SubscriptionDetailsTable initialSubscription={subscriptionId} isEditing={false} setIsEditing={() => { }} />
        </React.Fragment>
      ))
    }
  </>
);

export default SubscriptionsList;
