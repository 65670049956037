import axiosAPI from "../utilities/axios";

export default {
  getAll: async (props: any) => await axiosAPI.get("/customers", { params: props }),
  getCustomer: async (id: any) => await axiosAPI.get(`/customers/${id}`),
  updateCustomer: async (props: any) => await axiosAPI.put(`/customers/${props.id}`, props.customer),
  getActiveSubscriptions: async (p: any) => (
    await axiosAPI.get(`/customers/${p.id}/subscriptions?subscription_status=active`)),
  getCancelledSubscriptions: async (p: any) => (
    await axiosAPI.get(`/customers/${p.id}/subscriptions?subscription_status=canceled`)),
  getFailedSubscriptions: async (p: any) => (
    await axiosAPI.get(`/customers/${p.id}/subscriptions?subscription_status=failed`)),
  getExpiredSubscriptions: async (p: any) => (
    await axiosAPI.get(`/customers/${p.id}/subscriptions?subscription_status=expired`)),
  getPausedSubscriptions: async (p: any) => (
    await axiosAPI.get(`/customers/${p.id}/subscriptions?subscription_status=paused`)),
  getOneTimePurchases: async (props: any) => (
    await axiosAPI.get(`/customers/${props.id}/shopify_orders?page=${props.page}`)
  ),
};
