import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../constants/routes";
import { AgentNavItem } from "./NavItems";
import { ReactComponent as CustomerIcon } from "../icons/customer.svg";
// import { ReactComponent as UndoIcon } from "../icons/undo.svg";
import token from "../utilities/token";
// import OrderMenu from "./OrderMenu/OrderMenu"

const SideBar: React.FC = () => {
  const { pathname } = useLocation();
  let menuItem;

  const currentToken = token.get();
  const isTokenEmpty = (item) => item === "" || item === null;

  const checkToken = () => isTokenEmpty(currentToken);

  if (checkToken()) {
    menuItem = (
      <Nav variant="pills" className="flex-column" activeKey={pathname}>
        <AgentNavItem>
          <Nav.Link eventKey={Routes.LOGIN} as={Link} to={Routes.LOGIN}>
            <CustomerIcon className="mr-2" />
            Login
          </Nav.Link>
        </AgentNavItem>
      </Nav>
    );
  } else {
    menuItem = (
      <Nav variant="pills" className="flex-column" activeKey={pathname}>
        <AgentNavItem>
          <Nav.Link eventKey={Routes.CUSTOMERS} as={Link} to={Routes.CUSTOMERS}>
            <CustomerIcon className="mr-2" />
            Customers
          </Nav.Link>
        </AgentNavItem>
      </Nav>
    );
  }

  return (
    <div className="position-relative bg-dark text-white flex-shrink-0" style={{ width: "200px", height: "100%" }}>
      <h3 className="p-3">{process.env.REACT_APP_NAME ?? "PETLAB CO"}</h3>
      {menuItem}
    </div>
  );
};

export default SideBar;
