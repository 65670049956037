import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { STATES } from "../../constants/stateConstants";

type Props = {
  isEditing: boolean;
  prefix: string;
  name: string;
  value: string;
  handleChange: (event: any) => Promise<void>;
  error?: string;
  isTargetingUSA?: boolean;
};

const AddressInput: React.FC<Props> = (
  { isEditing, prefix, name, value, handleChange, error, isTargetingUSA = false }
) => (
  <Col>
    <Form.Group controlId={prefix + name}>
      {
        !isTargetingUSA
          ? <Form.Control
              disabled={!isEditing}
              placeholder={name.charAt(0).toUpperCase() + name.slice(1)}
              style={{ resize: "none" }}
              className={`bg-gray-light ${isEditing ? "border border-secondary " : "border-0 p-0 "}`}
              value={value || ""}
              name={name}
              onChange={handleChange}
          />
          : (
            <Form.Control
              as="select"
              disabled={!isEditing}
              name={name}
              defaultValue={value}
              onChange={handleChange}
              style={{ resize: "none" }}
              className={`bg-gray-light ${isEditing ? "border border-secondary " : "border-0 p-0 "}`}
            >
              {STATES.map(({ code, stateName }) => (
                <option key={code} value={code}>
                  {stateName}
                </option>
              ))}
            </Form.Control>
          )
      }
      {error && <p className="error text-danger">{error}</p>}
    </Form.Group>
  </Col>
);

export default AddressInput;
