import { all, put, takeEvery } from "redux-saga/effects";
import { productConstants } from "../constants/productConstants";
import { errorWorker } from "../utilities/errors";
import productService from "../services/productService";

function* getProducts(action: any) {
  try {
    const { data }: any = yield productService.getProducts(action.payload);
    yield put({
      type: productConstants.SET_PRODUCTS_AUTOCOMPLETE,
      payload: {
        products: data.data.products_list,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* productWatcher() {
  yield takeEvery(productConstants.GET_PRODUCTS_AUTOCOMPLETE, getProducts);
}

export default function* rootProductSaga() {
  yield all([productWatcher()]);
}
