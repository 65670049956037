/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
import { AwsRum, AwsRumConfig } from "aws-rum-web";

declare global {
  interface Window {
    AWS_RUM: AwsRum;
  }
}

if (typeof window !== "undefined" && process.env.REACT_APP_AWS_RUM_APP_ID) {
  let awsRum: AwsRum | null = null;

  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.REACT_APP_AWS_GUEST_ROLE,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID: string = process.env.REACT_APP_AWS_RUM_APP_ID;
    const APPLICATION_VERSION: string = "1.0.0";
    const APPLICATION_REGION: string = "us-east-1";

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
