import { CellValue } from "react-table";
import { Subscription, SubscriptionItem } from "../types/subscriptionTypes";

function calculateByQuantity(item: SubscriptionItem, quantity: number, tax: number): void {
  if (item && item.price !== undefined && item.quantity !== undefined) {
    const price = parseFloat((item.price / item.quantity).toFixed(2));
    item.price = parseFloat((price * +quantity).toFixed(2));
    item.tax_amount = price * +quantity * tax;
    item.row_total = price * +quantity + item.tax_amount;

    if (item.new_product) {
      item.taxAmount = price * +quantity * tax;
    }
  }
}

function calculateByPrice(item: SubscriptionItem, price: string, tax: number): void {
  const newPrice = parseFloat(price);
  item.quantity = 1;
  item.price = newPrice;
  item.tax_amount = newPrice * tax;
  item.row_total = newPrice + item.tax_amount;
}

export default function calculate(
  subscription: Subscription,
  details: SubscriptionItem[],
  index: number,
  key: string,
  value: CellValue
): void {
  const item = details[index];
  const tax = item.tax_amount ? item.tax_amount / item.price : item?.taxAmount / item?.price;

  if (key === "quantity") {
    calculateByQuantity(item, value || 1, tax || 0);
  }
  if (key.includes("price")) {
    calculateByPrice(item, Number.isNaN(Number(value)) ? 0 : value, tax);
  }
  subscription.taxAmount = details
    .reduce((prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.tax_amount || 0), 0)
    .toFixed(2);
  subscription.amount = details
    .reduce((prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.price || 0), 0)
    .toFixed(2);
  subscription.totalPrice = details
    .reduce((prev: number, cur: SubscriptionItem) => prev + Number(cur.row_total || 0), 0)
    .toFixed(2);
}
