import React from "react";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Routes } from "./constants/routes";
import { AgentRoute, PrivateRoute, PublicRoute } from "./components/Routes";
import SideBar from "./components/SideBar";
import NavigationBar from "./components/NavigationBar";
import Customers from "./containers/Customers";
import Customer from "./containers/Customer";
import Orders from "./containers/Orders";
import Order from "./containers/Order";
import Subscriptions from "./containers/Subscriptions";
import Subscription from "./containers/Subscription";
import Login from "./containers/Login";
import "./App.scss";
import "./trackers/awsRum/awsRum";

import SuccessModal from "./components/Modals/SuccessModal";
import ErrorModal from "./components/Modals/ErrorModal";
import OAuthCallback from "./containers/OauthCallback";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_PUBLIC_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_PUBLIC_COGNITO_DOMAIN,
      scope: ["email", "openid", "phone"],
      redirectSignIn: `${window.location.origin}/oauth2`,
      responseType: "code",
    },
  },
});

function App() {
  return (
    <Router>
      <div className="d-flex vh-100">
        <SideBar />
        <div className="flex-grow-1 d-flex flex-column overflow-hidden">
          <NavigationBar />
          <Switch>
            <PublicRoute exact path={Routes.OAUTH2}>
              <OAuthCallback />
            </PublicRoute>

            <PublicRoute exact path={Routes.LOGIN}>
              <Login />
            </PublicRoute>

            <AgentRoute exact path={Routes.CUSTOMERS}>
              <Customers />
            </AgentRoute>

            <AgentRoute exact path={Routes.CUSTOMER}>
              <Customer />
            </AgentRoute>

            <AgentRoute exact path={Routes.ORDERS}>
              <Orders />
            </AgentRoute>

            <AgentRoute exact path={Routes.ORDER}>
              <Order />
            </AgentRoute>

            <AgentRoute exact path={Routes.SUBSCRIPTIONS}>
              <Subscriptions />
            </AgentRoute>

            <AgentRoute exact path={Routes.SUBSCRIPTION}>
              <Subscription />
            </AgentRoute>

            <PrivateRoute path="/">
              <Redirect to={Routes.CUSTOMERS} />
            </PrivateRoute>
          </Switch>
        </div>
        <SuccessModal />
        <ErrorModal />
      </div>
    </Router>
  );
}

export default App;
