import React from "react";
import Button from "react-bootstrap/Button";
import { CellProps } from "react-table";
import { ReactComponent as SaveIcon } from "../../icons/save.svg";
import { SubscriptionItem } from "../../types/subscriptionTypes";

const SaveRowCell = <T extends object>({
  row: { original },
  isEditing,
  saveRow,
}: React.PropsWithChildren<CellProps<T>>): React.ReactElement<CellProps<T>> => {
  const item = original as SubscriptionItem;
  return (
    <>
      {isEditing && (item && item?.new_product) && (
        <Button
          disabled={!item?.product_id}
          variant="primary"
          onClick={() => saveRow(item)}
        >
          <SaveIcon />
        </Button>
      )}
    </>
  );
};

export default SaveRowCell;
